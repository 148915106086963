import React, { useState, useEffect, ChangeEvent, FormEvent, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import CheckCircle from "../Components/Icons/CheckCircle";
import { DatePicker, DatesProvider, DatePickerProps, DateValue, DatePickerInput } from "@mantine/dates";
import axios from "axios";
import { FormData, FoundAddresses, ITechnicianBooking, Option, DawaAutocompleteItemResultItem, CarouselItemProps } from "../types/types";
import moment from "moment";
import TextInput from "../Components/TextInput";
import Modal from "../Components/Modal";
import UploadFormData from "form-data";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Checkbox from "../Components/Checkbox";
import "dayjs/locale/da";
import { AggrementType, ModelType } from "../types/enums";

let ENV = "PROD";

const API = ENV === "PROD" ? "https://integration.bwt.dk/api" : "http://localhost:8080/api";

const responsive = {
	desktop: {
		breakpoint: { max: 6000, min: 1024 },
		items: 2,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		partialVisibilityGutter: 40,
	},
};
const responsiveMobile = {
	desktop: {
		breakpoint: { max: 6000, min: 1024 },
		items: 2,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const initialFormState: FormData = {
	installationDetails: {
		model: "",
		error: "",
		serialNumber: "",
		message: "",
		errorDescription: "",
		installationDate: null,
	},
	customerDetails: {
		customerNumber: "",
		installationNumber: "",
		hasAggreement: null,
		hasAggreementType: null,
	},
	contactDetails: {
		firstName: "",
		lastName: "",
		email: "",
		repeatEmail: "",
		address: "",
		postalCode: "",
		city: "",
		phone: "",
	},
	orderDetails: {
		date: null,
		time: null,
		callForQuickOrder: false,
		acceptedPayType: null,
		acceptedDetailsAreCorrect: null,
		wantServiceAggrement: null,
	},
};

const StepForm: React.FC = () => {
	const [formData, setFormData] = useState<FormData>(initialFormState);

	const pricePlusServ = 995;
	const priceBasicServ = 1495;
	const pricePremiumServ = 995;
	const [openIssues, setOpenIssues] = useState(true);
	const [issuesCompleted, setIssuesCompleted] = useState(false);
	const [openCustomerDetails, setOpenCustomerDetails] = useState(false);
	const [customerDetailsCompleted, setCustomerDetailsCompleted] = useState(false);
	const [openContactDetails, setOpenContactDetails] = useState(false);
	const [contactDetailsCompleted, setContactDetailsCompleted] = useState(false);
	const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
	const [time, setTime] = useState<String | null>(null);
	const [bookingId, setBookingId] = useState<number | null>(null);
	const [foundAddresses, setFoundAddresses] = useState<[FoundAddresses] | []>([]);
	const [addressAccepted, setAddressAccepted] = useState<boolean>(false);
	const [technicianAvailableDates, setTechnicianAvailableDates] = useState<[string] | []>([]);
	const [availableTimeSlots, setAvailableTimeSlots] = useState<[ITechnicianBooking] | []>([]);
	const [postalCodeVerified, setPostalCodeVerified] = useState<boolean>(false);
	const [technicianId, setTechnianId] = useState<number | null>(null);
	const [loadingServiceAggrement, setLoadingServiceAggrement] = useState<boolean>(false);
	const [serviceAggrementMsg, setServiceAggrementMsg] = useState<string>("");
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isBookingLoading, setIsBookingLoading] = useState<boolean>(false);
	const [touchedPostalCode, setTouchedPostalCode] = useState(false);
	const [touchedEmail, setTouchedEmail] = useState(false);
	const [touchedRepeatEmail, setTouchedRepeatEmail] = useState(false);
	const [touchedPhone, setTouchedPhone] = useState(false);
	const [touchedFirstName, setTouchedFirstName] = useState(false);
	const [touchedLastName, setTouchedLastName] = useState(false);
	const [touchedCity, setTouchedCity] = useState(false);
	const [touchedSerialNumber, setTouchedSerialNumber] = useState(false);
	const [isPostalCodeValid, setIsPostalCodeValid] = useState<boolean>(false);
	const [tryghedPremiumText, setTryghedPremiumText] = useState("Vælg");
	const [nyTryghedPremiumText, setNyTryghedPremiumText] = useState("Book service (0 kr.)");
	const [tryghedPlusText, setTryghedPlusText] = useState("Vælg");
	const [nyTryghedPlusText, setNyTryghedPlusText] = useState(`Book service (${pricePlusServ} kr.)`);

	const [basicTryghedPremiumText, setBasicTryghedPremiumText] = useState("Book service (0 kr.)");
	const [basicKeepAggrement, setBasicKeepAggrement] = useState("Book service (1495 kr.)");

	const [bookingHasBeenSent, setBookingHasBeenSent] = useState(false);
	const [contactDetailErrors, setContactDetailErrors] = useState<string[] | []>([]);
	const [shallTypeInstallationDate, setShallTypeInstallationDate] = useState<boolean | null>(null);
	const [isOffshore, setIsOffshore] = useState<boolean>(false);
	const location = useLocation();

	const selectFaultTypeRef = useRef<HTMLSelectElement>(null);
	const contactDetailsRef = useRef<HTMLDivElement>(null);

	const [techniciansUnavailableInPostal, setTechniciansUnavailableInPostal] = useState<boolean>(false);

	const closeModal = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		let { search } = location;
		let firstName: string,
			lastName: string,
			email: string,
			repeatEmail: string,
			phone: string,
			address: string,
			postalCode: string,
			city: string,
			productCode: string,
			customerNumber: string,
			model: string,
			error: string,
			errorTypeCode: string;
		
		let errorDescription: string = "";
			
		firstName = lastName = email = phone = address = postalCode = city = productCode = customerNumber = model = error = "";
		if (search) {
			search = search.substr(1);

			let searchJSON = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

			if ("firstName" in searchJSON) {
				firstName = searchJSON["firstName"];
			}
			// if ("lastName" in searchJSON) {
			// 	lastName = searchJSON["lastName"];
			// }

			if ("email" in searchJSON) {
				email = searchJSON["email"];
				repeatEmail = searchJSON["email"];
			}

			// if ("phone" in searchJSON) {
			// 	phone = searchJSON["phone"];

			// 	phone = phone.replace(/\s/g, "");
			// 	// if starts with +45 or 0045, then remove it
			// 	if (phone.startsWith("+45")) {
			// 		phone = phone.slice(3);
			// 	} else if (phone.startsWith("0045")) {
			// 		phone = phone.slice(4);
			// 	}
			// 	// if starts with 45 and length is 10, then remove 45
			// 	if (phone.startsWith("45") && phone.length === 10) {
			// 		phone = phone.slice(2);
			// 	}
			// }
			// if ("address" in searchJSON) {
			// 	address = searchJSON["address"];
			// 	if (address !== "" && address !== null) {
			// 		setAddressAccepted(true);
			// 	}
			// }
			// if ("postalCode" in searchJSON) {
			// 	postalCode = searchJSON["postalCode"];
			// 	verifyPostalCode(postalCode);
			// }
			// if ("city" in searchJSON) {
			// 	city = searchJSON["city"];
			// }

			if ("productCode" in searchJSON) {
				productCode = searchJSON["productCode"].toUpperCase();
			}
			
			if (productCode !== "" && firstName !== "") {
				getUserDetailsByProductCode(productCode, firstName);
			}

			// if ("customerNumber" in searchJSON) {
			// 	customerNumber = searchJSON["customerNumber"];
			// }

			if ("model" in searchJSON) {
				model = searchJSON["model"];
			}

			if ("errorType" in searchJSON) {
				error = searchJSON["errorType"];
				errorTypeCode = searchJSON["errorTypeCode"];

				if (error === "1" || error === "2" || error === "3") {
					error = "positionsfejlmotorfejl";
				}else if (error === "13") {
					error = "aqastopliterudlost";
				} else if (error === "14") {
					error = "aqastopsensorudlost";
				} else if (error === "15") {
					error = "aqawatchudlost";
				} else if (error === "32") {
					error = "rutinemaessigvedligehold"; 
				} else if (error === "34") {
					error = "ekspertservice";
				} else {
					errorDescription = error 
					error = "other";
				}
			}

			if ("errorTypeCode" in searchJSON) {
				if (errorDescription != "" && errorDescription != null) {
					errorDescription = errorDescription + "/" + searchJSON["errorTypeCode"];
				}
					
			}

			if (firstName || lastName || email || phone || address || postalCode || city || productCode) {
				setFormData((prevData) => ({
					...prevData,
					["contactDetails"]: {
						...prevData["contactDetails"],
						["firstName"]: firstName,
						["lastName"]: lastName,
						["email"]: email,
						["repeatEmail"]: repeatEmail,
						["phone"]: phone,
						["address"]: address,
						["postalCode"]: postalCode,
						["city"]: city,
					},
					["installationDetails"]: {
						...prevData["installationDetails"],
						["serialNumber"]: productCode,
						["model"]: model,
						["error"]: error,
						["errorDescription"]: errorDescription,
					},
					["customerDetails"]: {
						...prevData["customerDetails"],
						["customerNumber"]: customerNumber,
					},
				}));
			}
		}
	}, []);

	
	const getUserDetailsByProductCode = async (productCode: string, firstName: string) => {
		try {
			const response = await axios.get(`${API}/privateserviceaggrements/${productCode}/${firstName}`);

			setAddressAccepted(true);

			const serviceAggrement = response.data.serviceAgreements;
			setFormData((prevData) => ({
				...prevData,
				["contactDetails"]: {
					...prevData["contactDetails"],
					["firstName"]: serviceAggrement.name,
					["lastName"]: serviceAggrement.name,
					["email"]: serviceAggrement.mail,
					["repeatEmail"]: serviceAggrement.mail,
					["phone"]: serviceAggrement.phone,
					["address"]: serviceAggrement.address,
					["postalCode"]: serviceAggrement.postalCode,
					["city"]: serviceAggrement.city,
				},
				["installationDetails"]: {
					...prevData["installationDetails"],
					["serialNumber"]: productCode,
					["model"]: serviceAggrement.model
				},
				["customerDetails"]: {
					...prevData["customerDetails"],
					["customerNumber"]: serviceAggrement.customerNumber,
				},
			}));

			validateEmail(serviceAggrement.mail);
			validatePhone(serviceAggrement.phone);
			verifyPostalCode(serviceAggrement.postalCode);
			isContactDetailsValid();

		
		} catch (error) {
		}
	}

	// Hver gang brugeren skifter dato, så hent ledige tider på den gældende dato
	useEffect(() => {

		const fetchData = async () => {
			const date = formData.orderDetails.date;
			const postalCode = formData.contactDetails.postalCode;

			
			try {
				let _date = date !== null ? date.toISOString().split("T")[0] : ""; // in the format YYYY-MM-DD
				console.log("Henter tider for tekniker: " + technicianId + " på dato: " + _date + " i postnummer: " + postalCode + " for model: " + formData.installationDetails.model);
				const response = await axios.get(`${API}/technicianbooking/all/${technicianId}/${_date}/${postalCode}/${formData.installationDetails.model}`);
				setTime(null);
				setBookingId(null);
				setAvailableTimeSlots(response.data.teknikerBookings);

			} catch (error) {
				console.error("Error fetching dates:", error);
			}
		};
		if (formData.orderDetails.date !== null && isModalOpen !== true && technicianId !== null) {
			fetchData();
		}
	}, [formData.orderDetails.date, technicianId, formData.contactDetails.postalCode]);

	// Hent tekniker pr postnummer, hver gang brugeren skifter postnummer
	useEffect(() => {
		const fetchData = async () => {
			const postalCode = formData.contactDetails.postalCode;

			try {
				const response = await axios.get(`${API}/technicianpostals/${postalCode}`);

				if (response.data.user.length > 0) {
					setTechnianId(response.data.user[0].userId);
					setTechniciansUnavailableInPostal(false);
				} else {
					setTechnianId(null);
					setTechniciansUnavailableInPostal(true);
				}
			} catch (error: any) {
				setTechnianId(null);
				setTechniciansUnavailableInPostal(true);
			}
		};
		if (formData.contactDetails.postalCode) {
			fetchData();
		} else {
			setTechnianId(null);
		}
	}, [formData.contactDetails]);

	// Her hender vi teknikerens ledige datoer
	useEffect(() => {
		const fetchData = async () => {
			try {
				const postalCode = formData.contactDetails.postalCode;

				const serviceType = formData.installationDetails.error === "almservice" ? "servicebooking" : "errorbooking";

				const response = await axios.get(
					`${API}/technicianbooking/availabledates/${technicianId}/${postalCode}/${formData.installationDetails.model}/${serviceType}`
				);
				setTechnicianAvailableDates(response.data.teknikerBookingDates);

				console.log(response.data.teknikerBookingDates);
			} catch (error) {
				console.error("Error fetching dates:", error);
			}
		};

		if (technicianId !== null && formData.orderDetails.date === null) {
			setTechnicianAvailableDates([]);
			fetchData();
		}
	}, [technicianId, formData.orderDetails.date, formData.contactDetails.postalCode, formData.contactDetails.address]); // Empty array as the second argument to run effect only once (on mount)

	useEffect(() => {
		let errors: string[] = [];
		if (touchedEmail && !validateEmail(formData.contactDetails.email)) {
			if (formData.contactDetails.email !== "") {
				errors.push("Email er ikke gyldig");
			} else {
				errors.push("Email feltet er påkrævet");
			}
		}

		if (touchedPhone && !validatePhone(formData.contactDetails.phone)) {
			if (formData.contactDetails.phone !== "") {
				errors.push("Telefonnummer er ikke gyldigt. Det skal være 8 cifret");
			} else {
				errors.push("Telefonnummer er påkrævet");
			}
		}

		if (touchedRepeatEmail && formData.contactDetails.email !== formData.contactDetails.repeatEmail) {
			errors.push("Email er ikke ens");
		}

		if (touchedFirstName && formData.contactDetails.firstName === "") {
			errors.push("Fornavn er påkrævet");
		}

		if (touchedLastName && formData.contactDetails.lastName === "") {
			errors.push("Efternavn er påkrævet");
		}

		if (touchedCity && formData.contactDetails.city === "") {
			errors.push("By er påkrævet");
		}

		if (touchedSerialNumber && validateProductCode(formData.installationDetails.serialNumber) === false) {
			if (formData.installationDetails.serialNumber !== "") {
				errors.push("Produkt kode er ikke gyldig. Den skal være i formatet XXXX-XXXX");
			} else {
				errors.push("Produkt kode er påkrævet");
			}
		}

		if (touchedPostalCode && !postalCodeVerified) {
			if (formData.contactDetails.postalCode === "") {
				errors.push("Postnummer er påkrævet");
				setIsPostalCodeValid(false);
			} else {
				errors.push("Postnummer er ikke gyldigt");
				setIsPostalCodeValid(false);
			}
		}
		setContactDetailErrors(errors);
	}, [formData.contactDetails, formData.installationDetails.serialNumber, postalCodeVerified]);

	useEffect(() => {
		const postal = formData.contactDetails.postalCode.trim();
		setIsOffshore(
			postal === "8305" ||
				postal === "5985" ||
				postal === "5970" ||
				postal === "5960" ||
				postal === "4592" ||
				postal === "4944" ||
				postal === "3770" ||
				postal === "3790" ||
				postal === "3782" ||
				postal === "3760" ||
				postal === "3700" ||
				postal === "3720" ||
				postal === "3751" ||
				postal === "3740" ||
				postal === "3730" || postal === "5943"
				? true
				: false
		);
	}, [formData.contactDetails.postalCode]);

	useEffect(() => {
		setFormData((prevData) => ({
			...prevData,
			["orderDetails"]: {
				...prevData["orderDetails"],
				["wantServiceAggrement"]: null,
			},
		}));
	}, [formData.installationDetails.error]);

	const findServiceAggrements = async () => {
		setLoadingServiceAggrement(true);
		try {
			const response = await axios.post(`${API}/privateserviceaggrements`, {
				firstName: formData.contactDetails.firstName,
				lastName: formData.contactDetails.lastName,
				email: formData.contactDetails.email,
				phone: formData.contactDetails.phone,
				address: formData.contactDetails.address,
				postalCode: formData.contactDetails.postalCode,
				city: formData.contactDetails.city,
				productCode: formData.installationDetails.serialNumber,
			});

			
			if (response.data.msg === "Vi kan se at du har en BWT tryghedsaftale, som betyder at dit servicebesøg er dækket.") {
				// Tjek hvilken tryghedsaftale kunden har
				let hasAggrementType: string | null = null;
				let customerNumber: string = response.data.serviceAgreements.customerNumber;
				if (response.data.serviceAgreements.type === "TN") {
					hasAggrementType = AggrementType.premium;
				} else if (response.data.serviceAgreements.type === "TP") {
					hasAggrementType = AggrementType.plus;
				} else if (response.data.serviceAgreements.type === "TH") {
					hasAggrementType = AggrementType.basic;
				}

				let message: string | null = "Du er allerede dækket af en BWT tryghed " + hasAggrementType + " aftale, som betyder at dit servicebesøg er dækket.";

				setServiceAggrementMsg(message);
				setShallTypeInstallationDate(false);

				setOpenContactDetails(false);
				setContactDetailsCompleted(true);
				setOpenCustomerDetails(true);
				contactDetailsRef.current?.scrollIntoView();

				setFormData((prevData) => ({
					...prevData,
					["customerDetails"]: {
						...prevData["customerDetails"],
						["hasAggreement"]: true,
						["hasAggreementType"]: hasAggrementType,
						["customerNumber"]: customerNumber,
					},
				}));
			} else {
				setServiceAggrementMsg(response.data.msg);
				setShallTypeInstallationDate(true);
			}
			setLoadingServiceAggrement(false);
		} catch (error) {

			setServiceAggrementMsg("Du har på nuværende tidspunkt ikke en BWT tryghedsaftale. Vil du tegne en?");
			setFormData((prevData) => ({
				...prevData,
				["customerDetails"]: {
					...prevData["customerDetails"],
					["hasAggreement"]: false,
					["hasAggreementType"]: null,
				},
			}));
			setShallTypeInstallationDate(true);
		}
		setLoadingServiceAggrement(false);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, stepName: keyof FormData) => {
		let value = e.target.value;

		if (e.target.name === "address") {
			findAddress(e.target.value);
		}

		if (e.target.name === "postalCode") {
			setTouchedPostalCode(true);
			verifyPostalCode(e.target.value);
		}

		if (e.target.name === "email") {
			setTouchedEmail(true);
		}

		if (e.target.name === "repeatEmail") {
			setTouchedRepeatEmail(true);
		}

		if (e.target.name === "phone") {
			// remove spaces
			value = value.replace(/\s/g, "");
			// if starts with +45 or 0045, then remove it
			if (value.startsWith("+45")) {
				value = value.slice(3);
			} else if (value.startsWith("0045")) {
				value = value.slice(4);
			}
			// if starts with 45 and length is 10, then remove 45
			if (value.startsWith("45") && value.length === 10) {
				value = value.slice(2);
			}
			setTouchedPhone(true);
		}

		if (e.target.name === "firstName") {
			setTouchedFirstName(true);
		}

		if (e.target.name === "lastName") {
			setTouchedLastName(true);
		}

		if (e.target.name === "city") {
			setTouchedCity(true);
		}

		if (e.target.name === "serialNumber") {
			setTouchedSerialNumber(true);
			value = value.toUpperCase();

			const oldValue = formData.installationDetails.serialNumber;

			// check if the - is removed
			if (value.length === 4 && oldValue.length === 5) {
				value = value;
			} else {
				if (value.length === 4) {
					value = value + "-";
				}
			}
		}

		if (e.target.name !== "installationDate") {
			setShallTypeInstallationDate(false);
			if (stepName === "installationDetails") {
				setFormData((prevData) => ({
					...prevData,
					[stepName]: {
						...prevData[stepName],
						[e.target.name]: value,
						["installationDate"]: null,
					},
				}));
			} else {
				setFormData((prevData) => ({
					...prevData,
					[stepName]: {
						...prevData[stepName],
						[e.target.name]: value,
					},
					["installationDetails"]: {
						...prevData["installationDetails"],
						["installationDate"]: null,
					},
				}));
			}
		} else {
			setFormData((prevData) => ({
				...prevData,
				[stepName]: {
					...prevData[stepName],
					[e.target.name]: value,
				},
			}));
		}
	};

	const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>, stepName: keyof FormData) => {
		// If the model has changed, then reset the error to an empty string
		if (e.target.name === "model") {
			setFormData((prevData) => ({
				...prevData,
				[stepName]: {
					...prevData[stepName],
					[e.target.name]: e.target.value,
					["error"]: "",
				},
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[stepName]: {
					...prevData[stepName],
					[e.target.name]: e.target.value,
				},
			}));
		}
	};

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();

		setIsBookingLoading(true);

		const body = {
			userId: technicianId,
			available: 0,
			date: formData.orderDetails.date ? moment(formData.orderDetails.date.toISOString().split("T")[0]).format("YYYY-MM-DD") : null,
			timeSlot: time,
			source: "webform",
			customerNumber: formData.customerDetails.customerNumber,
			hasServiceAggrement: formData.customerDetails.hasAggreement ? 1 : 0,
			hasServiceAggrementType: formData.customerDetails.hasAggreement ? formData.customerDetails.hasAggreementType : null,
			wantServiceAggrement: formData.orderDetails.wantServiceAggrement !== "none" ? 1 : 0,
			wantServiceAggrementType: formData.orderDetails.wantServiceAggrement,
			model: formData.installationDetails.model,
			errorType: formData.installationDetails.error,
			firstName: formData.contactDetails.firstName,
			lastName: formData.contactDetails.lastName,
			email: formData.contactDetails.email,
			phone: formData.contactDetails.phone,
			address: formData.contactDetails.address,
			city: formData.contactDetails.city,
			postal: formData.contactDetails.postalCode,
			productCode: formData.installationDetails.serialNumber,
			acceptedContactByBWT: formData.orderDetails.callForQuickOrder,
			message: formData.installationDetails.message,
			installationDate: formData.installationDetails.installationDate ? moment(formData.installationDetails.installationDate).format("YYYY-MM-DD") : formData.installationDetails.installationDate,
			errorDescription: formData.installationDetails.errorDescription,
			status: "RECEIVED",
			acceptedDetailsAreCorrect: 1,
		};

		// moment(formData.installationDetails.installationDate).format("YYYY-MM-DD")

		// setIsBookingLoading(false);

		if (ENV === "PROD") {
			try {
				await axios.post(API + "/technicianbooking/book/" + bookingId, body);
				setIsBookingLoading(false);
				setIsModalOpen(false);
				resetForm();
				setBookingHasBeenSent(true);
			} catch (error) {
				setIsBookingLoading(false);
				setIsModalOpen(false);
			}
		} else {
			console.log(body);
			console.log("bookingId: " + bookingId);
			console.log(formData.installationDetails.installationDate);
			console.log(moment(formData.installationDetails.installationDate).format("YYYY-MM-DD"));



			// try {
			// 	const response = await axios.post(API + "/technicianbooking/book/" + bookingId, body);
			// 	console.log(response);
			// 	setIsBookingLoading(false);
			// 	setIsModalOpen(false);
			// 	resetForm();
			// 	setBookingHasBeenSent(true);
			// } catch (error) {
			// 	console.log(error);
			// 	setIsBookingLoading(false);
			// 	setIsModalOpen(false);
			// }


			setIsBookingLoading(false);
		}
	};

	const resetForm = () => {
		setFormData(initialFormState);
		setBookingId(null);
		setTime(null);
		setTechnicianAvailableDates([]);
		setAvailableTimeSlots([]);
		setPostalCodeVerified(false);
		setFoundAddresses([]);
		setAddressAccepted(false);
		setTechnianId(null);
		setLoadingServiceAggrement(false);
		setServiceAggrementMsg("");
		setIsModalOpen(false);
		setBookingHasBeenSent(false);
		setContactDetailErrors([]);
		setShallTypeInstallationDate(null);
		setIsOffshore(false);
	}

	const hidden = (open: boolean) => {
		return open === true ? "" : " hidden";
	};

	function getDateInFuture(amount: number): Date {
		const today = new Date();
		const yesterday = new Date(today);
		yesterday.setDate(today.getDate() + amount);
		return yesterday;
	}

	const getDayProps: DatePickerProps["getDayProps"] = (_date) => {
		// If the day is saturday or sunday, then disable it
		if (_date.getDay() === 6 || _date.getDay() === 0) {
			return { disabled: true };
		}

		if (technicianAvailableDates === undefined) {
			return {};
		}

		if (technicianAvailableDates.length <= 0) {
			return {};
		}

		const available = technicianAvailableDates.some((item) => item === _date.toISOString().split("T")[0]);

		const date = formData.orderDetails.date;

		if (date !== null && _date !== null) {
			if (_date.toISOString().split("T")[0].replace(/-/g, "") === date.toISOString().split("T")[0].replace(/-/g, "")) {
				return {
					style: {
						backgroundColor: "#00174B",
						color: "var(--mantine-color-white)",
						selected: "red",
					},
				};
			}
		}

		if (available) {
			return {
				style: {
					backgroundColor: "#00174B",
					opacity: 0.72,
					color: "var(--mantine-color-white)",
					selected: "red",
				},
			};
		} else {
			return { disabled: true };
		}
	};

	// Hvis året er det samme som i dag, så gør teksten blå og fed på årsknapperne
	const getYearControlProps: DatePickerProps["getYearControlProps"] = (date) => {
		if (date.getFullYear() === new Date().getFullYear()) {
			return {
				style: {
					color: "#00174B",
					fontWeight: 700,
				},
			};
		}

		if (date.getFullYear() === new Date().getFullYear() + 1) {
			return { disabled: true };
		}

		return {};
	};

	// Hvis måneden er februar, så gør teksten blå og fed på månedknapperne
	const getMonthControlProps: DatePickerProps["getMonthControlProps"] = (date) => {
		if (date.getMonth() === 1) {
			return {
				style: {
					color: "#00174B",
					fontWeight: 700,
				},
			};
		}

		if (date.getMonth() === 5) {
			return { disabled: true };
		}

		return {};
	};

	// Ud fra hvad brugeren skriver i adressefeltet, så find adresser fra Dawa
	const findAddress = async (address: string) => {
		try {
			const response = await axios.get(`https://api.dataforsyningen.dk/adresser/autocomplete?q=${address}&per_side=5`);

			if (response.data.length > 0) {
				const tempAddresses: [{ address: string; city: string; postalCode: string }] = response.data.map((item: DawaAutocompleteItemResultItem) => {
					return { address: item.tekst, city: item.adresse.postnrnavn, postalCode: item.adresse.postnr };
				});

				setFoundAddresses(tempAddresses);
			} else {
				setFoundAddresses([]);
			}
		} catch (error) {
		}
	};

	// Ud fra hvad brugeren skriver i postnummerfeltet, så kald API'en for at verificere postnummeret
	const verifyPostalCode = async (postalCode: string) => {
		// Hvis postnummeret er 4 cifre langt og alle cifre er tal, så kald API'et
		if (postalCode.length === 4 && !isNaN(Number(postalCode))) {
			try {
				const response = await axios.get(`https://api.dataforsyningen.dk/postnumre?nr=${postalCode}`);

				if (response.data.length === 1) {
					setPostalCodeVerified(true);
					return true;
				} else {
					setPostalCodeVerified(false);
					return false;
				}
			} catch (error) {
				setPostalCodeVerified(false);
				return false;
			}
		} else {
			setPostalCodeVerified(false);
			return false;
		}
	};

	// Når brugeren vælger en adresse fra listen, så opdater formdata med den valgte adresse
	const pickAddress = (item: FoundAddresses) => {
		setPostalCodeVerified(true);
		setAddressAccepted(true);
		setFoundAddresses([]);
		setFormData((prevData) => ({
			...prevData,
			["contactDetails"]: {
				...prevData["contactDetails"],
				["address"]: item.address,
				["city"]: item.city,
				["postalCode"]: item.postalCode,
			},
		}));
	};

	// Define options for the second select based on the selected anlæg
	const fejlOptions: Record<string, Option[]> = {
		[ModelType.bwtperla]: [
			{ value: "", label: "Vælg venligst fejl type" },
			{
				value: "almservice",
				label: "Booking af almindelig serviceeftersyn",
			},
			{
				value: "ekspertservice",
				label: "Anlæg melder ”Ekspert Service” på display eller i app",
			},
			{
				value: "positionsfejlmotorfejl",
				label: "Mit anlæg melder Positionsfejl, motorfejl",
			},
			{
				value: "vandisalgbeholder",
				label: "Der er vand i saltbeholderen",
			},
			{
				value: "rutinemaessigvedligehold",
				label: "Anlæg melder ”Rutinemæssig vedligehold” i displayet eller på min app",
			},
			{
				value: "regenereringsvolumenikkenået",
				label: "Mit anlæg melder regenereringsvolumen ikke opnået",
			},
			{
				value: "kalderforsalt",
				label: "Kalder på salt og der er salt i saltbeholderen",
			},
			{
				value: "ingeninternet",
				label: "Anlæg melder ingen internetforbindelse",
			},
			{
				value: "kanikkeforbindeapp",
				label: "Anlæg kan ikke forbinde til app",
			},
			{
				value: "ingenaktiveringsmail",
				label: "Jeg har ikke modtaget nogen aktiveringsmail",
			},
			{
				value: "elektronikfejl",
				label: "Elektronikfejl",
			},
			{
				value: "aqawatchudlost",
				label: "AQA Watch udløst",
			},
			{
				value: "aqastopliterudlost",
				label: "Aqa Stop liter udløst",
			},
			{
				value: "aqastopsensorudlost",
				label: "Aqa Stop sensor udløst",
			},
			{ value: "other", label: "Andet" },
		],
		[ModelType.aqalife]: [
			{ value: "", label: "Vælg venligst fejl type" },
			{
				value: "almservice",
				label: "Booking af almindelig serviceeftersyn",
			},
			{
				value: "kalderforsalt",
				label: "Kalder på salt og der er salt i saltbeholderen",
			},
			{
				value: "overcure",
				label: "Anlæg melder Overcure / Überstrom",
			},
			{
				value: "service",
				label: "Anlæg kalder på service eller ”Wartung”",
			},
			{
				value: "skylleriafloeb",
				label: "Anlæg skyller direkte i afløbet",
			},
			{
				value: "taellerikkened",
				label: "Anlæg tæller ikke ned i liter på display",
			},
			{
				value: "saltbeholdertor",
				label: "Saltbeholderen er helt tør",
			},
			{ value: "darligsmag", label: "Dårlig smag eller smager af salt" },
			{ value: "saltskaeg", label: "Der er saltudfældninger/saltskæg i karret" },
			{ value: "maalerhaardtvand", label: "Jeg måler hårdt vand" },
			{ value: "other", label: "Andet" },
		],
		[ModelType.bwtperlaone]: [
			{ value: "", label: "Vælg venligst fejl type" },
			{
				value: "almservice",
				label: "Booking af almindelig serviceeftersyn",
			},
			{
				value: "ekspertservice",
				label: "Anlæg melder ”Ekspert Service” på display eller i app",
			},
			{
				value: "positionsfejlmotorfejl",
				label: "Mit anlæg melder Positionsfejl, motorfejl",
			},
			{
				value: "vandisalgbeholder",
				label: "Der er vand i saltbeholderen",
			},
			{
				value: "rutinemaessigvedligehold",
				label: "Anlæg melder ”Rutinemæssig vedligehold” i displayet eller på min app",
			},
			{
				value: "regenereringsvolumenikkenået",
				label: "Mit anlæg melder regenereringsvolumen ikke opnået",
			},
			{
				value: "kalderforsalt",
				label: "Kalder på salt og der er salt i saltbeholderen",
			},
			{
				value: "ingeninternet",
				label: "Anlæg melder ingen internetforbindelse",
			},
			{
				value: "kanikkeforbindeapp",
				label: "Anlæg kan ikke forbinde til app",
			},
			{
				value: "ingenaktiveringsmail",
				label: "Jeg har ikke modtaget nogen aktiveringsmail",
			},
			{
				value: "elektronikfejl",
				label: "Elektronikfejl",
			},
			{
				value: "aqawatchudlost",
				label: "AQA Watch udløst",
			},
			{ value: "other", label: "Andet" },
		],
		[ModelType.aqabasic]: [
			{ value: "", label: "Vælg venligst fejl type" },
			{
				value: "almservice",
				label: "Booking af almindelig serviceeftersyn",
			},
			{
				value: "kalderforsalt",
				label: "Kalder på salt og der er salt i saltbeholderen",
			},
			{
				value: "p05error",
				label: "Anlæg melder P05/TBD fejl",
			},
			{
				value: "service",
				label: "Anlæg blinker i displayet",
			},
			{
				value: "skylleriafloeb",
				label: "Anlæg skyller direkte i afløbet",
			},
			{
				value: "taellerikkened",
				label: "Mit anlæg tæller ikke ned i liter på display",
			},
			{
				value: "saltbeholdertor",
				label: "Saltbeholderen er helt tør",
			},
			{ value: "maalerikkehaardheder", label: "Jeg måler hårdt vand" },
			{ value: "darligsmag", label: "Dårlig smag eller smager af salt" },
			{ value: "saltskaeg", label: "Der er saltudfældninger/saltskæg i karet" },
			{ value: "other", label: "Andet" },
		]
	};

	const handleDateChanged = (date: DateValue) => {
		setFormData((prevData) => ({
			...prevData,
			orderDetails: {
				...prevData["orderDetails"],
				["date"]: date,
			},
		}));
	};

	const [imageState, setImageState] = useState({
		currentImages: [] as File[],
		error: "",
	});

	function handleDeleteImage(imageName: any) {
		setImageState({
			...imageState,
			currentImages: imageState.currentImages.filter((image) => image.name !== imageName),
		});
	}

	function handleImageChange(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.files === null) return;
		if (imageState.currentImages.length >= 15) {
			setImageState({ ...imageState, error: "You have already selected the max of 15 images." });
			return;
		}
		setImageState({ ...imageState, currentImages: [...imageState.currentImages, event.target.files[0]] });
	}

	function handleOnDrop(event: any) {
		event.preventDefault();
		event.stopPropagation();
		if (imageState.currentImages.length >= 15) {
			setImageState({ ...imageState, error: "You have already selected the max of 15 images." });
			return;
		}
		setImageState({
			...imageState,
			currentImages: imageState.currentImages.concat(Array.from(event.dataTransfer.files)),
		});
		const div = document.getElementById("dragAndDropArea");
		if (div === null) return;
		div.classList.remove("ring-2", "ring-blue-500");
	}

	function handleDragOver(event: any) {
		event.stopPropagation();
		event.preventDefault();
		const div = document.getElementById("dragAndDropArea");
		if (div === null) return;
		div.classList.add("ring-2", "ring-blue-500");
	}

	function handleDragEnter(event: any) {
		const div = document.getElementById("dragAndDropArea");
		if (div === null) return;
		div.classList.add("ring-2", "ring-blue-500");
	}

	function handleDragLeave(even: any) {
		const div = document.getElementById("dragAndDropArea");
		if (div === null) return;
		div.classList.remove("ring-2", "ring-blue-500");
	}

	function uploadImages() {
		if (imageState.currentImages.length > 0) {
			const files: File[] = imageState.currentImages;

			const formData = new UploadFormData();
			files.forEach((file, index) => {
				formData.append(`image${index}`, file);
			});

			fetch("https://booking.bwt.dk/upload.images.php", {
				method: "POST",
				body: JSON.stringify(formData),
			})
				.then((response) => {
					console.log(response);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	function getAggrementPrice(model: string, type: string): number {
		if (model === ModelType.aqalife || model === ModelType.bwtperla || model === ModelType.bwtperlahome) {
			if (type === AggrementType.premium) {
				return 209;
			} else if (type === AggrementType.plus) {
				return 169;
			} else if (type === AggrementType.basic) {
				return 89;
			}
		} else if (model === ModelType.aqabasic) {
			if (type === AggrementType.premium) {
				return 169;
			} else if (type === AggrementType.plus) {
				return 129;
			} else if (type === AggrementType.basic) {
				return 69;
			}
		} else if (model === ModelType.bwtperlaone) {
			if (type === AggrementType.premium) {
				return 179;
			} else if (type === AggrementType.plus) {
				return 139;
			} else if (type === AggrementType.basic) {
				return 79;
			}
		}
		return 0;
	}

	function getAggUpgPrice(model: string, type: string): number {
		if (model === ModelType.aqalife || model === ModelType.bwtperla || model === ModelType.bwtperlahome) {
			if (type === AggrementType.plus) {
				return 480;
			} else if (type === AggrementType.basic) {
				return 1440;
			}
		} else if (model === ModelType.aqabasic || model === ModelType.bwtperlaone) {
			if (type === AggrementType.plus) {
				return 480;
			} else if (type === AggrementType.basic) {
				return 1200;
			}
		} 
		return 0;
	}

	function serviceAggrementIsValid() {
		const _hasAggrementType = formData.customerDetails.hasAggreementType;
		const _hasAggrement = formData.customerDetails.hasAggreement;
		const _wantServiceAggrement = formData.orderDetails.wantServiceAggrement;
		if (_hasAggrement === true && _hasAggrementType === AggrementType.premium && _wantServiceAggrement === AggrementType.premiumpayforalmserv) {
			return false;
		} else if (
			(_hasAggrement === false ||
				serviceAggrementMsg ===
					"Vi kan se du tidligere har haft en BWT tryghedsaftale. Ønsker du at genoptage denne eller vil du betale for det enkelte besøg?") &&
			_wantServiceAggrement !== null
		) {
			return false;
		} else if (_hasAggrementType === AggrementType.plus && (_wantServiceAggrement === AggrementType.upgradefromplustopremium || _wantServiceAggrement === AggrementType.pluspayforservice)) {
			return false;
		} else if (_hasAggrementType === AggrementType.basic && (_wantServiceAggrement === AggrementType.upgradefrombasictopremium || _wantServiceAggrement === AggrementType.basicpayforalmserv)) {
			return false;
		} else if ((_hasAggrementType === AggrementType.premium || _hasAggrementType === AggrementType.basic || _hasAggrementType === AggrementType.plus) && formData.installationDetails.error != "almservice") {
			return false; 
		} else {
			return true;
		}
	}

	const validateEmail = (email: string) => {
		if (typeof email !== 'string') return false;
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(email);
	};

	// validate phone number
	function validatePhone(phone: string) {
		const re = /^\d{8}$/;
		return re.test(phone);
	}

	// validate that the productcode is in the format XXXX-XXXX where X can be a number or a letter in uppercase
	function validateProductCode(productCode: string) {
		const re = /^[A-ZÆØÅ0-9]{4}-[A-ZÆØÅ0-9]{4}$/;
		return re.test(productCode);
	}

	function isContactDetailsValid() {
		if (formData.contactDetails.firstName === "") {
			return true;
		}

		if (formData.contactDetails.lastName === "") {
			return true;
		}

		if (validateEmail(formData.contactDetails.email) === false) {
			return true;
		}

		if (formData.contactDetails.email !== formData.contactDetails.repeatEmail) {
			return true;
		}

		if (validatePhone(formData.contactDetails.phone) === false) {
			return true;
		}

		if (formData.contactDetails.address === "") {
			return true;
		}

		if (formData.contactDetails.postalCode === "") {
			return true;
		}

		if (formData.contactDetails.city === "") {
			return true;
		}

		if (formData.installationDetails.serialNumber === "") {
			return true;
		}

		if (validateProductCode(formData.installationDetails.serialNumber) === false) {
			return true;
		}

		if (validateEmail(formData.contactDetails.email) === false) {
			return true;
		}

		if (
			shallTypeInstallationDate === true &&
			(formData.installationDetails.installationDate === null || formData.installationDetails.installationDate === undefined)
		) {
			return true;
		}

		if (technicianId === null) {
			return true;
		}

		if (isOffshore === true) {
			return true;
		}

		if ( shallTypeInstallationDate === true) {
			if (formData.installationDetails && formData.installationDetails.installationDate) {
				const date = new Date(formData.installationDetails.installationDate);
				if (!isNaN(date.getTime())) {
					// This means the installationDate is valid and therefore the contact details are valid 
				} else {
					return true; 
				}
			} else {
				return true; 
			}
		}

		return false;
	}

	const executeRef = () => {
		selectFaultTypeRef.current?.scrollIntoView();
	};

	function isEligibleForFreeServiceAggrement() {
		const installationDateStr = formData?.installationDetails?.installationDate;
		if (!installationDateStr) {
			return false;
		}

		const installationDate = new Date(installationDateStr);
		const today = new Date();

		// Check if installation date is invalid or in the future
		if (isNaN(installationDate.getTime()) || installationDate > today) {
			return false;
		}

		// Calculate the difference in milliseconds
		const timeDiff = today.getTime() - installationDate.getTime();

		// Convert milliseconds to years
		const diffYears = timeDiff / (1000 * 3600 * 24 * 365.25);

		return diffYears <= 2;
	}

	const updateInstallationType = (type: string) => {
		setFormData((prevData) => ({
			...prevData,
			["installationDetails"]: {
				...prevData["installationDetails"],
				["model"]: type,
				["error"]: "",
			},
		}));
		executeRef();
	}

	return (
		<>
			<div className="max-w-3xl mx-auto mt-4 mb-4 md:mb-6 px-1 md:px-0">
				<div className="max-h-8 md:max-h-12 flex h-screen">
					<div>
						<img className="max-h-8 md:max-h-14" src="./BWT_Logo_with_Subline_Positive_360x162.png" alt="bwtchangetheworldlogo" />
					</div>
					<div className="m-auto mr-0 ">
						<h3 className="text-sm font-semibold md:text-2xl text-[#00174B]">Bestil BWT servicetekniker</h3>
					</div>
				</div>
			</div>
			{!bookingHasBeenSent ? (
				<div>
					{/* 1 Fejltype */}
					<div className={`border mt-2 max-w-3xl mx-auto px-4 py-2 md:py-4 ${ENV === "PROD" ? "bg-gray-50" : "bg-red-200"}  rounded-t-lg`}>
						<div className="flex items-center justify-between font-semibold text-gray-900 transition-transform duration-600">
							<StepHeader
								num="1"
								name="Fejltype"
								completed={!openIssues}
								onClick={() => {
									// Open the step again if it is not open
									if (!openIssues) {
										setOpenIssues(true);
										setOpenContactDetails(false);
										setIssuesCompleted(false);
										setContactDetailsCompleted(false);
										setOpenCustomerDetails(false);
										setCustomerDetailsCompleted(false);
										setOpenDateTimePicker(false);
									}
								}}
							/>
							<span className="ml-2">
								{openIssues && issuesCompleted === false ? (
									<svg className={`h-5 w-5 flex-none text-gray-400 `} viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
										<path
											fill-rule="evenodd"
											d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
											clip-rule="evenodd"
										/>
									</svg>
								) : (
									<CheckCircle className="w-6 h-6 stroke-green-500" />
								)}
							</span>
						</div>
						<div className={`mt-1 text-gray-600 ${hidden(openIssues && issuesCompleted === false)} mt-6`}>
							<label className="block text-gray-700 text-sm font-bold mb-4">Hvilket anlæg ønsker du at bestille en tekniker til?</label>

							<form>
								<div className="grid grid-cols-2 md:grid-cols-4 gap-2 mb-4">
									<div
										key={1}
										className={`border p-2 text-center hover:border-[#00174B] rounded cursor-pointer ${
											formData.installationDetails.model === "bwtperla" ? "border-[#00174B]" : formData.installationDetails.model == "" ? "" : "opacity-60"
										}`}
										onClick={() => updateInstallationType("bwtperla")}
									>
										<img className="m-auto" src="https://shop1158.sfstatic.io/upload_dir/pics/Booking/perla.webp" alt="" />
										<h3 className="text-md font-semibold my-2">PERLA</h3>
									</div>
									<div
										key={2}
										className={`border p-2 text-center hover:border-[#00174B] rounded cursor-pointer ${
											formData.installationDetails.model === "bwtperlaone" ? "border-[#00174B]" : formData.installationDetails.model == "" ? "" : "opacity-60"
										}`}
										onClick={() => updateInstallationType("bwtperlaone")}
									>
										<img className="m-auto" src="https://shop1158.sfstatic.io/upload_dir/pics/Booking/perlaone.webp" alt="" />
										<h3 className="text-md font-semibold my-2">PERLA ONE</h3>
									</div>
									<div
										key={3}
										className={`border p-2 text-center hover:border-[#00174B] rounded cursor-pointer ${
											formData.installationDetails.model === "aqalife" ? "border-[#00174B]" : formData.installationDetails.model == "" ? "" : "opacity-60"
										}`}
										onClick={() => updateInstallationType("aqalife")}
									>
										<img className="m-auto" src="https://shop1158.sfstatic.io/upload_dir/pics/Booking/aqalife.webp" alt="" />
										<h3 className="text-md font-semibold my-2">AQA LIFE</h3>
									</div>
									<div
										key={4}
										className={`border p-2 text-center hover:border-[#00174B] rounded cursor-pointer ${
											formData.installationDetails.model === "aqabasic" ? "border-[#00174B]" : formData.installationDetails.model == "" ? "" : "opacity-60"
										}`}
										onClick={() => updateInstallationType("aqabasic")}
									>
										<img className="m-auto" src="https://shop1158.sfstatic.io/upload_dir/pics/Booking/basic.webp" alt="" />
										<h3 className="text-md font-semibold my-2">AQA BASIC</h3>
									</div>
								</div>
								<div className={`grid grid-cols-1 gap-2 mb-2`}>
									<label className="block text-gray-700 text-sm font-bold">Hvad er problemet med dit anlæg?</label>
									<select
										ref={selectFaultTypeRef}
										id="fejl"
										className="w-full border p-2"
										value={formData.installationDetails.error}
										onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleSelectChange(event, "installationDetails")}
										name="error"
									>
										{formData.installationDetails.model != "" ? (
											<>
												{fejlOptions[formData.installationDetails.model].map((option, index) => (
													// <option disabled={option.value === ""} key={option.value} value={option.value}>
													<option key={index} value={option.value}>
														{option.label}
													</option>
												))}
											</>
										) : null}
									</select>
								</div>

								{/* If a model i picked and error is picked, show possible solution */}
								{formData.installationDetails.error !== "" && formData.installationDetails.model !== "" ? (
									<Solution fejlOptions={fejlOptions} formData={formData} />
								) : null}

								{formData.installationDetails.error === "other" ? (
									<div className="relative flex mb-4">
										<textarea
											id="errorDescription"
											rows={2}
											className="block p-2.5 w-full text-sm mt-3 text-gray-900 bg-gray-50 rounded border border-gray-300 dark:border-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 "
											placeholder="Beskriv problemet du har med dit anlæg her"
											value={formData.installationDetails.errorDescription}
											name="errorDescription"
											onChange={(e) => handleChange(e, "installationDetails")}
										></textarea>
									</div>
								) : null}

								{/* 							
								<div>
									<label className="block text-gray-700 text-sm font-bold">Tilføj venligst nogle billeder af fejlen på anlægget</label>
									<div className="bg-gray-100 flex my-2 justify-center">
										<form
											onDragOver={handleDragOver}
											onDragEnter={handleDragEnter}
											onDragLeave={handleDragLeave}
											onDrop={(event) => handleOnDrop(event)}
											id="dragAndDropArea"
											className="flex justify-center flex-1 p-2"
										>
											<input className="hidden" type="file" id="file" accept="image/*" onChange={handleImageChange}></input>
											<label htmlFor="file">
												<strong className="text-blue-600 cursor-pointer hover:text-blue-500">Vælg fil</strong> eller træk og slip filer her
											</label>
										</form>
									</div>
								</div>

								{imageState.error.length ? <p className="bg-red-400 text-white p-2">{imageState.error}</p> : ""}
								{imageState.currentImages.length ? (
									<div className="flex flex-row flex-wrap bg-gray-100 p-2">
										{imageState.currentImages.map((image: File) => {
											return (
												<div className="mx-2 relative">
													<img src={URL.createObjectURL(image)} className="max-h-32" />
													<div onClick={() => handleDeleteImage(image.name)} className="absolute top-0 right-0">
														<XMark className="bg-black text-white hover:text-[#FF88BD] cursor-pointer h-4 w-4" />
													</div>
												</div>
											);
										})}
									</div>
								) : (
									""
								)}

								<button type="button" onClick={uploadImages} className="text-white px-4 py-2 rounded bg-[#00174B] hover:bg-sky-900 disabled:bg-[#666B6E]">
									Upload
								</button> 
								*/}

								<button
									type="button"
									disabled={
										formData.installationDetails.model === "" ||
										formData.installationDetails.error === "" ||
										(formData.installationDetails.error === "other" && formData.installationDetails.errorDescription === "")
									}
									onClick={() => {
										// setOpenCustomerDetails(true);
										setOpenIssues(false);

										setOpenContactDetails(true);
										// setOpenCustomerDetails(false);
										// setCustomerDetailsCompleted(true);
									}}
									className="text-white px-4 py-2 rounded bg-[#00174B] hover:bg-sky-900 disabled:bg-[#666B6E]"
								>
									Næste
								</button>
							</form>
						</div>
					</div>
					{/* 2 Kontaktinformation */}
					<div className="border-x border-b max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 ">
						<div ref={contactDetailsRef} className="flex items-center justify-between font-semibold text-gray-900 transition-transform duration-600">
							{/* <a href="#" className="font-semibold text-gray-900 flex items-center transition-transform duration-600"> */}
							<StepHeader
								num="2"
								name="Kontaktinformation"
								completed={!openContactDetails && !openIssues}
								onClick={() => {
									// Open the step again if it is not open
									if (!openIssues) {
										setOpenIssues(false);
										setOpenContactDetails(true);
										setIssuesCompleted(true);
										setContactDetailsCompleted(false);
										setOpenCustomerDetails(false);
										setCustomerDetailsCompleted(false);
										setOpenDateTimePicker(false);
									}
								}}
							/>
							<span className="ml-2">
								{contactDetailsCompleted === false ? (
									<svg
										className={`h-5 w-5 flex-none text-gray-400 transform ${openContactDetails ? "rotate-0" : "rotate-180"}`}
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											fill-rule="evenodd"
											d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
											clip-rule="evenodd"
										/>
									</svg>
								) : (
									<CheckCircle className="w-6 h-6 stroke-green-500" />
								)}
							</span>
						</div>
						<div className={`${hidden(openContactDetails && contactDetailsCompleted === false)} mt-6`}>
							<form>
								<div className="grid grid-cols-2 gap-2">
									<div className="mb-2 md:mb-4">
										<TextInput
											tabIndex={1}
											disabled={false}
											name="Fornavn *"
											autoComplete="given-name"
											value={formData.contactDetails.firstName}
											onChange={handleChange}
											inputName="firstName"
											className={`w-full border p-2 text-sm md:text-base ${
												formData.contactDetails.firstName.length > 0
													? "border-b-1 border-[#00174B]"
													: touchedFirstName
													? "border-b-1 border-red-500"
													: ""
											}`}
											formIndex="contactDetails"
										/>
									</div>
									<div className="mb-2 md:mb-4">
										<TextInput
											tabIndex={2}
											disabled={false}
											name="Efternavn *"
											autoComplete="family-name"
											value={formData.contactDetails.lastName}
											onChange={handleChange}
											inputName="lastName"
											className={`w-full border p-2 text-sm md:text-base ${
												formData.contactDetails.lastName.length > 0
													? "border-b-1 border-[#00174B]"
													: touchedLastName
													? "border-b-1 border-red-500"
													: ""
											}`}
											formIndex="contactDetails"
										/>
									</div>
								</div>
								<div className="mb-2 md:mb-4">
									<TextInput
										tabIndex={3}
										disabled={false}
										name="Email *"
										autoComplete="email"
										value={formData.contactDetails.email}
										onChange={handleChange}
										inputName="email"
										className={`w-full border p-2 text-sm md:text-base ${
											validateEmail(formData.contactDetails.email) ? "border-b-1 border-[#00174B]" : touchedEmail ? "border-b-1 border-red-500" : ""
										}`}
										formIndex="contactDetails"
										type="email"
									/>
								</div>
								<div className="mb-2 md:mb-4">
									<TextInput
										tabIndex={3}
										disabled={false}
										name="Gentag Email *"
										autoComplete="repeatEmail"
										value={formData.contactDetails.repeatEmail}
										onChange={handleChange}
										inputName="repeatEmail"
										className={`w-full border p-2 text-sm md:text-base ${
											formData.contactDetails.repeatEmail === formData.contactDetails.email && touchedRepeatEmail ? "border-b-1 border-[#00174B]" : touchedRepeatEmail ? "border-b-1 border-red-500" : ""
										}`}
										formIndex="contactDetails"
										type="repeatEmail"
									/>
								</div>
								<div className="mb-2 md:mb-4">
									<label className="block text-gray-700 text-sm font-bold">Telefonnummer *</label>
									<div className="grid grid-cols-10">
										<input
											type="text"
											name="prefix"
											value="+45"
											disabled
											className="col-span-2 md:col-span-1 w-full border p-2 text-sm md:text-base"
										/>
										<input
											tabIndex={4}
											type="text"
											name="phone"
											autoComplete="tel"
											value={formData.contactDetails.phone}
											onChange={(e) => handleChange(e, "contactDetails")}
											className={`col-span-8 md:col-span-9 w-full border p-2 text-sm md:text-base ${
												validatePhone(formData.contactDetails.phone)
													? "border-b-1 border-[#00174B]"
													: touchedPhone
													? "border-b-1 border-red-500"
													: ""
											}`}
											placeholder="XXXXXXXX"
										/>
									</div>
								</div>
								<div className="mb-2 md:mb-4">
									<TextInput
										tabIndex={5}
										disabled={false}
										name="Adresse *"
										autoComplete="address-line1"
										value={formData.contactDetails.address}
										onChange={handleChange}
										inputName="address"
										className={`w-full p-2 border ${addressAccepted ? "border-b-1 border-[#00174B]" : ""}`}
										formIndex="contactDetails"
									/>
									<ul role="list" className={`divide-y divide-gray-200 px-2 ${foundAddresses.length > 0 ? "border" : ""}`}>
										{foundAddresses.map((item, index) => (
											<li key={index} className="flex justify-between gap-x-6 py-2" onClick={() => pickAddress(item)}>
												<div className="flex min-w-0 gap-x-4">
													<div className="min-w-0 flex-auto">
														<p className="text-sm font-semibold leading-6 text-gray-900">{item.address}</p>
													</div>
												</div>
												<div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
													<button onClick={() => pickAddress(item)} className="text-sm leading-6  text-white px-4 rounded bg-[#00174B]">
														Vælg
													</button>
												</div>
											</li>
										))}
									</ul>
								</div>
								<div className="grid grid-cols-2 gap-2">
									<div className="mb-2 md:mb-4">
										<TextInput
											disabled={false}
											name="By *"
											autoComplete="address-level2"
											value={formData.contactDetails.city}
											onChange={handleChange}
											inputName="city"
											className={`w-full border p-2 text-sm md:text-base ${
												formData.contactDetails.city.length > 0 ? "border-b-1 border-[#00174B]" : touchedCity ? "border-b-1 border-red-500" : ""
											}`}
											formIndex="contactDetails"
											placeholder="Greve"
										/>
									</div>
									<div className="mb-2 md:mb-4">
										<TextInput
											disabled={false}
											name="Postnummer *"
											autoComplete="postal-code"
											value={formData.contactDetails.postalCode}
											onChange={handleChange}
											inputName="postalCode"
											className={`w-full border p-2 text-sm md:text-base ${
												postalCodeVerified
													? "border-b-1 border-[#00174B]"
													: touchedPostalCode && !isPostalCodeValid
													? "border-b-1 border-red-500"
													: ""
											}`}
											formIndex="contactDetails"
											placeholder="XXXX"
										/>
									</div>
								</div>
								<div className="mb-2 md:mb-4">
									<TextInput
										disabled={false}
										toolTip={[
											"Du finder registreringsmærkaten",
											"med en ottecifret kode direkte på dit BWT-anlæg.",
											"Kan du ikke finde produktkoden, så kan du skrive 0000-0000",
										]}
										name="Produktkode *"
										value={formData.installationDetails.serialNumber}
										onChange={handleChange}
										inputName="serialNumber"
										className={`w-full border p-2 text-sm md:text-base ${
											validateProductCode(formData.installationDetails.serialNumber)
												? "border-b-1 border-[#00174B]"
												: touchedSerialNumber
												? "border-b-1 border-red-500"
												: ""
										}`}
										formIndex="installationDetails"
										placeholder="XXXX-XXXX"
									/>
								</div>

								{shallTypeInstallationDate === true ? (
									<div className="mb-2 md:mb-4">
										<label className="block text-gray-700 text-sm font-bold">Købs-/dato for idriftsættelse *</label>
										<div className={`border px-2 ${formData.installationDetails.installationDate != null ? "border-b-1 border-[#00174B]" : ""}`}>
											<DatesProvider settings={{ consistentWeeks: true, locale: "da-DK", firstDayOfWeek: 1, timezone: "Europe/Copenhagen" }}>
												<DatePickerInput
													className="bg-white"
													size="md"
													leftSectionPointerEvents="none"
													placeholder="Vælg dato"
													value={formData.installationDetails.installationDate}
													onChange={(value) => {
														setFormData((prevData) => ({
															...prevData,
															["installationDetails"]: {
																...prevData["installationDetails"],
																["installationDate"]: value,
															},
														}));
													}}
													radius="0"
													maxDate={new Date()}
													variant="unstyled"
												/>
											</DatesProvider>
										</div>
									</div>
								) : null}

								{contactDetailErrors.length > 0 ? (
									<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
										<ul>
											{contactDetailErrors.map((error, index) => (
												<li key={index}>{error}</li>
											))}
										</ul>
									</div>
								) : null}

								{techniciansUnavailableInPostal || isOffshore ? (
									<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
										<h1 className="font-semibold">
											Det er desværre ikke muligt for dig at bestille en tid. Du skal derfor kontakte kundeservice på telefon +45 43 600 500 for at
											kunne bestille dit servicebesøg.
										</h1>
									</div>
								) : null}

								<button
									type="button"
									onClick={() => {
										setOpenIssues(true);
										setOpenContactDetails(false);
										setIssuesCompleted(false);
										setContactDetailsCompleted(false);
									}}
									className="text-white px-4 py-2 mr-4 rounded bg-[#666B6E]"
								>
									Tilbage
								</button>
								<button
									type="button"
									disabled={isContactDetailsValid()}
									onClick={() => {
										if (shallTypeInstallationDate === true) {
											setOpenContactDetails(false);
											setContactDetailsCompleted(true);
											setOpenCustomerDetails(true);
											contactDetailsRef.current?.scrollIntoView();
										} else {
											findServiceAggrements();
										}
									}}
									className="text-white px-4 py-2 rounded bg-[#00174B] hover:bg-sky-900 disabled:bg-[#666B6E]"
								>
									Næste
								</button>
							</form>
						</div>
					</div>
					{/* 3 Tryghedsaftale */}
					<div className="border-x border-b max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 ">
						<div className="flex items-center justify-between font-semibold text-gray-900 transition-transform duration-600">
							<StepHeader
								num="3"
								name="Tryghedsaftale"
								completed={openDateTimePicker}
								onClick={() => {
									// Open the step again if it is not open
									if (openDateTimePicker) {
										setOpenIssues(false);
										setOpenContactDetails(false);
										setIssuesCompleted(true);
										setContactDetailsCompleted(true);
										setOpenCustomerDetails(true);
										setCustomerDetailsCompleted(false);
										setOpenDateTimePicker(false);
									}
								}}
							/>
							<span className="ml-2">
								{customerDetailsCompleted === false ? (
									<svg
										className={`h-5 w-5 flex-none text-gray-400 transform ${
											openCustomerDetails ? "rotate-0" : "rotate-180"
										} transition-transform duration-300`}
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											fill-rule="evenodd"
											d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
											clip-rule="evenodd"
										/>
									</svg>
								) : (
									<CheckCircle className="w-6 h-6 stroke-green-500" />
								)}
							</span>
						</div>
						<div className={`text-gray-600 mt-6 ${hidden(openCustomerDetails && customerDetailsCompleted === false)}`}>
							{!isEligibleForFreeServiceAggrement() && formData.customerDetails.hasAggreementType ? (
								<>
									{formData.installationDetails.error === "almservice" &&
									formData.customerDetails.hasAggreementType === AggrementType.plus ? (
										<div>
											<h1 className="font-semibold">
												Du har på nuværende tidspunkt en Tryghed Plus aftale, som kun er dækket delvist. Du har nu mulighed for at opgradere eller
												fortsætte med din nuværende aftale.
											</h1>
											<Carousel className="pt-4" responsive={responsive} partialVisible={true}>
												<CarouselItem 
													id={1} 
													highligtedTitle="Din pris med nuværende aftale" 
													isEligibleForFreeServiceAggrement={isEligibleForFreeServiceAggrement()} 
													aggType="PLUS" 
													price={getAggrementPrice(formData.installationDetails.model, AggrementType.plus)} 
													li1={true} li2={true} li3={true} li4={false} li5={false} li6={false} 
													onBtnClick={() => {
														setNyTryghedPlusText("Valgt book service (995 kr.)");
														setNyTryghedPremiumText("Book service (0 kr.)");
														setFormData((prevData) => ({
															...prevData,
															["orderDetails"]: {
																...prevData["orderDetails"],
																["wantServiceAggrement"]: AggrementType.pluspayforservice,
															},
														}));
													}}
													btnText={nyTryghedPlusText} 
													btnClass={formData.orderDetails.wantServiceAggrement === AggrementType.pluspayforservice ? "bg-white text-black" : "text-white bg-[#00174B] hover:text-black hover:bg-white"}
													type="keep"
													/>
												<CarouselItem 
													id={1} 
													highligtedTitle="... eller opgrader til flere fordele" 
													isEligibleForFreeServiceAggrement={isEligibleForFreeServiceAggrement()} 
													aggType="PREMIUM" 
													price={getAggrementPrice(formData.installationDetails.model, AggrementType.premium)} 
													li1={true} li2={true} li3={true} li4={true} li5={true} li6={true} 
													onBtnClick={() => {
														setNyTryghedPlusText("Book service (995 kr.)");
														setNyTryghedPremiumText("Valgt book service (0 kr.)");
														setFormData((prevData) => ({
															...prevData,
															["orderDetails"]: {
																...prevData["orderDetails"],
																["wantServiceAggrement"]: AggrementType.upgradefromplustopremium,
															},
														}));
													}}
													btnText={nyTryghedPremiumText} 
													btnClass={formData.orderDetails.wantServiceAggrement === AggrementType.upgradefromplustopremium ? "bg-white text-black"	: "text-white bg-[#00174B] hover:text-black hover:bg-white"}
													type="upg"
													/>
											</Carousel>
											<div className="my-2 text-sm">
												*<sup>1</sup>, <sup>2</sup>, <sup>3</sup> Læs betingelser og vilkår, lige{" "}
												<a
													className="text-[#00174B] font-bold underline hover:text-[#FF88BD]"
													href="https://www.bwt.com/da-dk/kundeservice/serviceaftaler/tryghedsaftaler-til-private/"
													target="_blank"
												>
													her
												</a>
												.
											</div>
										</div>
									) : null}

									{formData.installationDetails.error === "almservice" &&
									formData.customerDetails.hasAggreementType === AggrementType.basic ? (
										<div>
											<h1 className="font-semibold">
												Du har på nuværende tidspunkt en Tryghed Basic aftale, som kun er dækket delvist. Du har nu mulighed for at opgradere eller
												fortsætte med din nuværende aftale.
											</h1>
											<Carousel className="pt-4" responsive={responsive} partialVisible={true}>
												<CarouselItem 
													id={3} 
													highligtedTitle="Din pris med nuværende aftale" 
													isEligibleForFreeServiceAggrement={isEligibleForFreeServiceAggrement()} 
													aggType="BASIC" 
													price={getAggrementPrice(formData.installationDetails.model, AggrementType.basic)} 
													li1={true} li2={false} li3={false} li4={false} li5={false} li6={false} 
													onBtnClick={() => {
														setBasicKeepAggrement("Valgt book service (1495 kr.)");
															setBasicTryghedPremiumText("Opgrader (0 kr.)");
															setFormData((prevData) => ({
																...prevData,
																["orderDetails"]: {
																	...prevData["orderDetails"],
																	["wantServiceAggrement"]: AggrementType.basicpayforalmserv,
																},
															}));
													}}
													btnText={basicKeepAggrement} 
													btnClass={formData.orderDetails.wantServiceAggrement === AggrementType.basicpayforalmserv ? "bg-white text-black" : "text-white bg-[#00174B] hover:text-black hover:bg-white"}
													type="keep"
													/>
												<CarouselItem 
													id={1} 
													highligtedTitle="... eller opgrader til flere fordele" 
													isEligibleForFreeServiceAggrement={isEligibleForFreeServiceAggrement()} 
													aggType="PREMIUM" 
													price={getAggrementPrice(formData.installationDetails.model, AggrementType.premium)} 
													li1={true} li2={true} li3={true} li4={true} li5={true} li6={true} 
													onBtnClick={() => {
														setBasicKeepAggrement("Book service (1495 kr.)");
														setBasicTryghedPremiumText("Valgt opgradering (0 kr.)");
														setFormData((prevData) => ({
															...prevData,
															["orderDetails"]: {
																...prevData["orderDetails"],
																["wantServiceAggrement"]: AggrementType.upgradefrombasictopremium,
															},
														}));
													}}
													btnText={basicTryghedPremiumText} 
													btnClass={formData.orderDetails.wantServiceAggrement === AggrementType.upgradefrombasictopremium ? "bg-white text-black"	: "text-white bg-[#00174B] hover:text-black hover:bg-white"}
													type="upg"
													/>
											</Carousel>

											<div className="my-2 text-sm">
												*<sup>1</sup>, <sup>2</sup>, <sup>3</sup> Læs betingelser og vilkår, lige{" "}
												<a
													className="text-[#00174B] font-bold underline hover:text-[#FF88BD]"
													href="https://www.bwt.com/da-dk/kundeservice/serviceaftaler/tryghedsaftaler-til-private/"
													target="_blank"
												>
													her
												</a>
												.
											</div>
										</div>
									) : null}

									{formData.installationDetails.error != "almservice" && (formData.customerDetails.hasAggreementType === AggrementType.premium || formData.customerDetails.hasAggreementType === AggrementType.basic || formData.customerDetails.hasAggreementType === AggrementType.plus) ? (
										<div className="mb-2">
											<h1 className="font-semibold">
												Dit anlæg er registreret og dækket af en BWT Tryghed {formData.customerDetails.hasAggreementType} aftale, og servicebesøget af derfor inkluderet.
											</h1>
											{serviceAggrementMsg ===
											"Du er allerede dækket af en BWT tryghed " +
												formData.customerDetails.hasAggreementType +
												" aftale, som betyder at dit servicebesøg er dækket." ? (
												<h1 className="font-semibold">Tryk Næste for at finde en ledig dato og tidspunkt</h1>
											) : null}
										</div>
									) : null}

									{formData.installationDetails.error === "almservice" && formData.customerDetails.hasAggreementType === AggrementType.premium ? (
										<div className="mb-2">
											<h1 className="font-semibold">
												Du er på nuværende tidspunkt dækket af en BWT Tryghed Premium aftale, som inkluderer et fast servicebesøg hver 2. år. Ønsker du et servicebesøg ud over dette, kan du få dette til en reduceret pris.
											</h1>

											<Checkbox
												title={`Ja tak, jeg ønsker at betale ${pricePremiumServ},- for et ekstraordinært servicebesøg`}
												value={formData.orderDetails.wantServiceAggrement === AggrementType.premiumpayforalmserv ? true : false}
												onChange={(e: ChangeEvent) => {

													const value = (e.target as HTMLInputElement).checked;

													if (value) {
														setTryghedPlusText("Vælg");
														setTryghedPremiumText("Vælg");
														setFormData((prevData) => ({
															...prevData,
															["orderDetails"]: {
																...prevData["orderDetails"],
																["wantServiceAggrement"]: AggrementType.premiumpayforalmserv,
															},
														}));
													} else {
														setTryghedPlusText("Vælg");
														setTryghedPremiumText("Vælg");
														setFormData((prevData) => ({
															...prevData,
															["orderDetails"]: {
																...prevData["orderDetails"],
																["wantServiceAggrement"]: "none",
															},
														}));
													}

												}}
											/>
										</div>
									) : null}
								</>
							) : null}

							{!isEligibleForFreeServiceAggrement() &&
							!formData.customerDetails.hasAggreementType &&
							serviceAggrementMsg === "Du har på nuværende tidspunkt ikke en BWT tryghedsaftale. Vil du tegne en?" ? (
								<div>
									<h1 className="font-semibold">{serviceAggrementMsg}</h1>
								</div>
							) : null}

							{isEligibleForFreeServiceAggrement() ? (
								<div>
									<h1 className="font-semibold">
										Ud fra den indtastede Købs-/dato for idriftsættelse, er dit anlæg ved registrering dækket af en BWT Tryghed Premium aftale.
									</h1>
								</div>
							) : null}

							{!isEligibleForFreeServiceAggrement() && serviceAggrementMsg === "" ? (
								<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
									<h1 className="font-semibold">
										Det er desværre ikke muligt for dig at bestille en tid. Du skal derfor kontakte kundeservice på telefon +45 43 600 500 for at
										kunne bestille dit servicebesøg.
									</h1>
								</div>
							) : null}

							{loadingServiceAggrement ? <div className="loader w-10 h-10" /> : null}

							{serviceAggrementMsg === "Du har på nuværende tidspunkt ikke en BWT tryghedsaftale. Vil du tegne en?" ||
							serviceAggrementMsg ===
								"Vi kan se du tidligere har haft en BWT tryghedsaftale. Ønsker du at genoptage denne eller vil du betale for det enkelte besøg?" ? (
								<div>
									{!isEligibleForFreeServiceAggrement() ? (
										<Carousel responsive={responsive} partialVisible={true}>
											<CarouselItem 
												id={1} 
												highligtedTitle="MEST POPULÆRE VALG" 
												isEligibleForFreeServiceAggrement={isEligibleForFreeServiceAggrement()} 
												aggType="PREMIUM" 
												price={getAggrementPrice(formData.installationDetails.model, AggrementType.premium)} 
												li1={true} li2={true} li3={true} li4={true} li5={true} li6={true} 
												onBtnClick={() => {
													setTryghedPlusText("Vælg");
													setTryghedPremiumText("Valgt");
													setFormData((prevData) => ({
														...prevData,
														["orderDetails"]: {
															...prevData["orderDetails"],
															["wantServiceAggrement"]: AggrementType.premium,
														},
													}));
												}}
												btnText={tryghedPremiumText} 
												btnClass={formData.orderDetails.wantServiceAggrement === AggrementType.premium ? "bg-white text-black"	: "text-white bg-[#00174B] hover:text-black hover:bg-white"}
												type="new"
												/>
											<CarouselItem 
												id={3} 
												highligtedTitle="BEGRÆNSET DÆKNING" 
												isEligibleForFreeServiceAggrement={isEligibleForFreeServiceAggrement()} 
												aggType="PLUS" 
												price={getAggrementPrice(formData.installationDetails.model, AggrementType.plus)} 
												li1={true} li2={true} li3={true} li4={false} li5={false} li6={false} 
												onBtnClick={() => {
													setTryghedPlusText("Valgt");
													setTryghedPremiumText("Vælg");
													setFormData((prevData) => ({
														...prevData,
														["orderDetails"]: {
															...prevData["orderDetails"],
															["wantServiceAggrement"]: AggrementType.plus,
														},
													}));
												}}
												btnText={tryghedPlusText} 
												btnClass={formData.orderDetails.wantServiceAggrement === AggrementType.plus ? "bg-white text-black" : "text-white bg-[#00174B] hover:text-black hover:bg-white"}
												type="new"
												/>
										</Carousel>
									) : (
										<Carousel responsive={responsiveMobile} partialVisible={true}>
											<CarouselItem 
												id={2} 
												highligtedTitle="GRATIS FØRSTE 2 ÅR" 
												isEligibleForFreeServiceAggrement={isEligibleForFreeServiceAggrement()} 
												aggType="PREMIUM" 
												price={getAggrementPrice(formData.installationDetails.model, AggrementType.premium)} 
												li1={true} li2={true} li3={true} li4={true} li5={true} li6={true} 
												onBtnClick={() => {
													setTryghedPlusText("Vælg");
														setTryghedPremiumText("Valgt");
														setFormData((prevData) => ({
															...prevData,
															["orderDetails"]: {
																...prevData["orderDetails"],
																["wantServiceAggrement"]: AggrementType.premium,
															},
														}));
												}}
												btnText={tryghedPremiumText} 
												btnClass={formData.orderDetails.wantServiceAggrement === AggrementType.premium ? "bg-white text-black"	: "text-white bg-[#00174B] hover:text-black hover:bg-white"}
												type="upg"
												/>
										</Carousel>
									)}
									<div className="my-2 text-sm">
										*<sup>1</sup>, <sup>2</sup>, <sup>3</sup> Læs betingelser og vilkår, lige{" "}
										<a
											className="text-[#00174B] font-bold underline hover:text-[#FF88BD]"
											href="https://www.bwt.com/da-dk/kundeservice/serviceaftaler/tryghedsaftaler-til-private/"
											target="_blank"
										>
											her
										</a>
										.
									</div>
									<Checkbox
										title="Nej tak, jeg ønsker ikke at registrere mit anlæg og accepterer at betale for det enkelte servicebesøg, såfremt den konstaterede fejl ikke er dækket af garantien"
										value={formData.orderDetails.wantServiceAggrement === "none" ? true : false}
										undertext="(Pris for standard servicebesøg er fra 1.995 kr. inkl. Moms)"
										onChange={() => {
											setTryghedPlusText("Vælg");
											setTryghedPremiumText("Vælg");
											setFormData((prevData) => ({
												...prevData,
												["orderDetails"]: {
													...prevData["orderDetails"],
													["wantServiceAggrement"]: "none",
												},
											}));
										}}
									/>
								</div>
							) : null}

							<button
								type="button"
								onClick={() => {
									setOpenCustomerDetails(false);
									setOpenContactDetails(true);
									setContactDetailsCompleted(false);
								}}
								className="text-white px-4 py-2 mr-4 mt-4 rounded bg-[#666B6E]"
							>
								Tilbage
							</button>
							<button
								type="button"
								disabled={serviceAggrementIsValid()}
								onClick={() => {
									setOpenDateTimePicker(true);
									setOpenCustomerDetails(false);
									setCustomerDetailsCompleted(true);
								}}
								className="text-white px-4 py-2 rounded bg-[#00174B] hover:bg-sky-900 disabled:bg-[#666B6E]"
							>
								Næste
							</button>
						</div>
					</div>
					{/* 4 Dato og Tid */}
					<div className="border-x border-b max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 rounded-b-lg">
						<div className="justify-between font-semibold text-gray-900 flex items-center transition-all duration-300">
							<StepHeader num="4" name="Dato og Tid" onClick={() => {}} />
							<span className="ml-2">
								<svg
									className={`h-5 w-5 flex-none text-gray-400 transform ${
										openDateTimePicker ? "rotate-0" : "rotate-180"
									} transition-transform duration-300`}
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
										clipRule="evenodd"
									/>
								</svg>
							</span>
							{/* </a> */}
						</div>
						<div className={`${hidden(openDateTimePicker && customerDetailsCompleted === true && contactDetailsCompleted === true)} mt-6`}>
							<div className="mt-2 grid sm:grid-cols-1 md:grid-cols-2 content-center">
								<div className={`text-gray-600 items-center flex justify-center`}>
									<DatesProvider settings={{ consistentWeeks: true, locale: "da-DK", firstDayOfWeek: 1, timezone: "GMT" }}>
										<DatePicker
											className="align-center"
											minDate={getDateInFuture(1)}
											maxDate={getDateInFuture(180)}
											allowDeselect
											value={formData.orderDetails.date}
											onChange={(date) => handleDateChanged(date)}
											getDayProps={getDayProps}
											getYearControlProps={getYearControlProps}
											getMonthControlProps={getMonthControlProps}
										/>
									</DatesProvider>
								</div>
								<div className="mt-2 text-sm">
									{formData.orderDetails.date !== null ? (
										<div className="border p-2 text-center bg-white shadow-lg">
											Ledige tider
											<br />{" "}
											{formData.orderDetails.date !== null
												? moment(formData.orderDetails.date.toISOString().split("T")[0]).format("DD-MM-YYYY")
												: ""}
										</div>
									) : (
										<div className="border p-4 text-center bg-white shadow-lg">Vælg en dato for at se ledige tider</div>
									)}

									<div className="grid grid-cols-1 gap-2 my-4">
										{formData.orderDetails.date !== null && availableTimeSlots.length > 0
											? availableTimeSlots.map((item) => (
													<button
														className={`w-full  py-2  hover:bg-white hover:text-black border border-[#00174B] text-sm text-center shadow-md border-1 rounded ${
															time === item.timeSlot ? "bg-white text-black" : "text-white bg-[#00174B] hover:text-black hover:bg-white"
														}`}
														onClick={() => {
															setTime(item.timeSlot);
															setBookingId(item.id);
														}}
													>
														{item.timeSlot}
													</button>
											  ))
											: null}

										{formData.orderDetails.date !== null && availableTimeSlots.length === 0 ? (
											<p className="text-center">Ingen ledige tider</p>
										) : null}
									</div>
								</div>
							</div>

							<Checkbox
								title="Jeg accepterer at BWT må kontakte mig telefonisk, hvis der er en ledig tid før den valgte dato"
								value={formData.orderDetails.callForQuickOrder ? true : false}
								onChange={() => {
									setFormData({
										...formData,
										orderDetails: {
											...formData.orderDetails,
											callForQuickOrder: !formData.orderDetails.callForQuickOrder,
										},
									});
								}}
							/>

							<div className="relative flex mb-4">
								<textarea
									id="message"
									rows={2}
									className="block p-2.5 w-full text-sm mt-3 text-gray-900 bg-gray-50 rounded border border-gray-300 dark:border-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 "
									placeholder="Har du nogle sidste bemærkninger til din booking?"
									value={formData.installationDetails.message}
									name="message"
									onChange={(e) => handleChange(e, "installationDetails")}
								></textarea>
							</div>

							<div className="mt-8">
								<button
									type="button"
									onClick={() => {
										setOpenCustomerDetails(true);
										setCustomerDetailsCompleted(false);
										setOpenDateTimePicker(false);
									}}
									className="text-white px-4 py-2 mr-4 rounded bg-[#666B6E]"
								>
									Tilbage
								</button>
								<button
									disabled={formData.orderDetails.date === null || time === null} // If date and time is not selected, then disable the button
									type="button"
									onClick={() => {
										setOpenDateTimePicker(true);
										setOpenContactDetails(false);
										setContactDetailsCompleted(true);
										setIsModalOpen(true);
									}}
									className="text-white px-4 py-2 rounded bg-[#00174B] disabled:bg-[#666B6E]"
								>
									Næste
								</button>
							</div>
							<Modal
								isEligibleForFreeServiceAggrement={isEligibleForFreeServiceAggrement}
								setFormData={setFormData}
								isOpen={isModalOpen}
								onClose={closeModal}
								formData={formData}
								date={formData.orderDetails.date}
								time={time}
								onSubmit={handleSubmit}
								isLoading={isBookingLoading}
								getAggrementPrice={getAggrementPrice}
								priceUpgPlusPrem={getAggUpgPrice(formData.installationDetails.model, AggrementType.plus)}
								priceUpgBasicPrem={getAggUpgPrice(formData.installationDetails.model, AggrementType.basic)}
								pricePlusServ={pricePlusServ}
								pricePremiumServ={pricePremiumServ}
								priceBasicServ={priceBasicServ}
							/>
						</div>
					</div>
				</div>
			) : (
				<div className="border mt-2 max-w-3xl mx-auto px-4 py-2 md:py-4 bg-gray-50 rounded-t-lg">
					<div className="flex items-center justify-between font-semibold text-gray-900 transition-transform duration-600">
						<p>Tak for din booking. Du vil indenfor 2 hverdage modtage en ordrebekræftelse</p>
						<span>
							<CheckCircle className="w-6 h-6 stroke-green-500" />
						</span>
					</div>
				</div>
			)}
		</>
	);
};

export default StepForm;

// StepHeader component
const StepHeader: React.FC<{ num: string; name: string; completed?: boolean; onClick: () => void }> = ({ num, name, completed = false, onClick }) => {
	return (
		<div className={`flex items-center ${completed ? "hover:cursor-pointer" : ""} `} onClick={onClick}>
			<p className="text-md md:text-lg w-5">{num}</p>
			<p className="text-md md:text-lg">{name}</p>
		</div>
	);
};

// component for displaying a possible solution to the user for the selected error and model
const Solution: React.FC<{ formData: FormData; fejlOptions: Record<string, Option[]> }> = ({ formData, fejlOptions }) => {
	let model = formData.installationDetails.model;
	let error = formData.installationDetails.error;

	return (
		<>
			{error !== "other" ? (
				<div className="bg-gray-50 border p-4 rounded-lg my-4">
					

					{/* PERLA ANLÆG */}
					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "almservice" ? (
						<div className="">
							<p className="text-gray-900 font-semibold">Et almindeligt serviceeftersyn omfatter:</p>
							<ul className="text-gray-600">
								<li>- Kontrol af installationen</li>
								<li>- Kontrol af regenerationsautomatik</li>
								<li>- Kontrol af brinemængde</li>
								<li>- Tjek af hydrauliske, elektriske og mekaniske dele</li>
								<li>- Udskiftning af klorcelle (sliddel) hver 4. år</li>
								<li>- Hårdhedstest og korrekte indstillinger af anlæg</li>
								<li>- Servicerapport med udført arbejde via e-mail</li>
							</ul>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "ekspertservice" ? (
						<div>
							<p className="text-gray-900 font-semibold">
								Dit Perla anlæg har behov for et Ekspert Service hvert 24. måned, og her skal du booke et servicebesøg.
							</p>
							<br />
							<p className="text-gray-900 font-semibold">Et Ekspert serviceeftersyn omfatter:</p>
							<ul className="text-gray-600">
								<li>- Opdatering af anlægget til den nyeste version</li>
								<li>- Gennemgået om og målt efter (reg. på begge sider)</li>
								<li>- Anlægges automatisk service program sættes over med IoClean</li>
								<li>- Funktionstester AQA Stop Floor Sensor</li>
								<li>- Kontrol af installationen</li>
								<li>- Kontrol af regenerationsautomatik</li>
								<li>- Kontrol af brinemængde</li>
								<li>- Tjek af hydrauliske, elektriske og mekaniske dele</li>
								<li>- Udskift af slid- og reservedele</li>
								<li>- Udskift af klorcelle (sliddel) Hver 4. år</li>
								<li>- Hårdhedstest og korrekt indstilling af anlæg</li>
								<li>- Servicerapport med udført arbejde via e-mail</li>
							</ul>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "positionsfejlmotorfejl" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">
								Frakobl strømmen til anlægget og vent 2 minutter. Når anlægget igen kobles til strøm vil den forsøge at bringe sig selv tilbage i fejlfri tilstand.
							</p>
							<br />
							<p className="">Hvis fejlen ikke forsvinder, så er der behov for en BWT servicetekniker</p>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome") && error === "vandisalgbeholder" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">Der må ikke være vand i saltbeholderen. Anlægget sørger selv for at påfylde det nødvendige vand til at opløse de salttabs der ligger i saltbeholderen</p>
							<p className="">Kontroller om der er et knæk på den lille sorte plastikslange der forbinder anlægget med afløbet</p>
							<br />
							<p className="">Finder du ikke en fejl i forbindelse med slangen er der behov for at du booker en BWT servicetekniker til at udbedre fejlen</p>
						</div>
					) : null}

					{(model === "bwtperlaone") && error === "vandisalgbeholder" ? (
						<div>
							<p className="">Der må ikke være vand i saltbeholderen. Anlægget sørger selv for at påfylde det nødvendige vand til at opløse de salttabs der ligger i saltbeholderen</p>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<ul className="list-disc px-6">
								<li>Kontroller om der er et knæk på den lille sorte plastikslange der forbinder anlægget med afløbet</li>
								<li>Kontroller at alle elektriske forbindelser er etableret. Der er 2 stik fra styringen i kabinettet der skal sidde sammen med de matchende stik på hydraulikken.</li>
								<li>Kontroller slangeforbindelser. Der er en slange fra kabinettet der skal være tilsluttet en 90° vinkel. Den sorte slange fra afløbet skal sidde på en hvid magnetventil.</li>
							</ul>
							<p className="">Finder du ikke en fejl i forbindelse med slangen er der behov for at du booker en BWT servicetekniker til at udbedre fejlen</p>
						</div>
					) : null}
						

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "rutinemaessigvedligehold" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag:</p>
							<p className="">
								Dit Perla anlæg vil bede om Rutinemæssig vedligehold hvert 6. måned. Dette sker for at sikre en høj hygiejne i anlægget. Her skal du bruge
								en IoClean, og hvis du ikke har sådan en, kan du købe en pakke på vores webshop{" "}
								<a href="https://shop.bwt.dk/webshop/salt-og-tilbehoer-til-bloedgoeringsanlaeg/bwt-ioclean" target="_blank">
									her
								</a>
							</p>
							<p className="">Det Rutinemæssige vedligehold hver 6. måned skal udføres af anlægsejeren. Har du brug for en tekniker til at udfører opgaven kan denne bookes længere nede, men denne opgave vil blive faktureret</p>
							<br />
							<p className="text-gray-900 font-semibold">Du kan følge step-by-step guiden, som vises på displayet eller denne quick guide:</p>
							<div className="">
								<img className="mb-2" src="booking_perla1.png" alt="" />
								<img className="mb-2" src="booking_perla2.png" alt="" />
								<img className="mb-2" src="booking_perla3.png" alt="" />
								<img className="mb-2" src="booking_perla4.png" alt="" />
							</div>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "regenereringsvolumenikkenået" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<ul className="text-gray-600">
								<li>- Kontroller om dit bypass er åbent</li>
								<li>- Kontroller om der er knæk på slangerne der går til og fra anlægget.</li>
							</ul>

							<div className="relative flex flex-col items-center max-w-screen-xl mx-auto md:flex-row">
								<div className="flex items-center py-5 md:w-1/2 md:pb-20 md:pt-10 md:pr-10">
									<div className="text-left">
										<p className="text-gray-900 font-semibold">Sådan by-passer du dit anlæg</p>
										<p className="max-w-md mx-auto mt-3 text-base text-gray-500 md:mt-5 md:max-w-3xl">
											Der er typisk lukkehaner som på billedet:
											<br />
											Her ser du et bypass der betegnes som lukket. dvs. at de 2 haner der er tilsluttet anlægget, er åbne så vandet løber igennem
											og den sidste hane mellem de 2 andre er lukket. På denne måde ledes vandet gennem anlægget og der leveres blødt vand til
											husstanden.
											<br />
											Tommelfingerregel: Vandet løber samme vej som bladene på hanen vender. Altså, er hanen drejet så bladene går langs med røret
											er hanen åben og vandet kan løbe igennem. Er hanen på tværs af rørene kan vandet ikke løbe igennem og hanen er derfor lukket.
										</p>
									</div>
								</div>
								<div className="flex items-center md:w-1/2 ">
									<div className="relative w-full p-3 rounded  md:p-8">
										<div className="rounded-lg bg-white text-black w-full">
											<img src="image.png" />
										</div>
										<div className="rounded-lg bg-white text-black w-full">
											<img src="Picture1.png" />
										</div>
									</div>
								</div>
							</div>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "kalderforsalt" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p>Grunden til denne melding kan være salttabletterne laver en ”saltbro”. En saltbro kan opstå ved opfugtning i saltkarret. Dette kan skyldes at anlægget står varmt, f.eks. op ad gulvvarmeanlæg, teknikskabe, eller direkte på gulvvarme. Forsøg at røre rundt så langt ned i saltbeholderen du kan, med f.eks. en grydeske. Er der rigtig meget salt i beholderen kan det være nødvendigt at tage det op for at sikre der er salttabletter helt nede på bunden af karret. Husk at fjerne beskeden fra displayet. </p>
							<p>Hvis meldingen kommer igen, så er der behov for en BWT servicetekniker. </p>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "taellerikkened" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">
								Tjek om dit bypass er lukket - Hvis det står åbent løber vandet uden om anlægget, vandet bliver derfor ikke blødgjort, og displayet tæller
								derved ikke ned.
							</p>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "ingeninternet" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p>Der er 3 slags forbindelser der er tilgængelige:</p><br />
							<ol>
								<li>1: GSM. Mobilsignal der kan være lavt afhængig af installations forhold. Kommunikationen fra anlægget kan gå langsomt, med denne forbindelse.</li>
								<li>2: Wi-FI. Hurtig forbindelse uden ledning.</li>
								<li>3: LAN. Den mest stabile forbindelse, der trækkes med et kabel mellem anlæg og router.</li>
							</ol><br />
							<p>Anlægget er udstyret med en guide, så du nemt kan vælge og oprette forbindelse.</p><br />
							<p>Hvis din GSM forbindelse fejler, eller kun viser en signal streg, så brug en af de andre forbindelser.</p><br />
							<p>Hvis din Wi-Fi forbindelse lykkes men du stadig oplever problemer, så udfør en forbindelsestest på anlægget. Dette gøres via følgende menu gennemgang: Indstillinger -&gt; Generelt -&gt; Forbindelse -&gt; Test din forbindelse / Forbindelses test. Viser testen at forbindelsen er mislykket kan der være problemer med din router. Følgende porte skal være åbne i routeren: 443 og 80. Prøv at skifte til en LAN forbindelse og kontroller om det afhjælper problemet.</p><br />
							<p>Hvis din LAN forbindelse lykkes men du stadig oplever problemer, så udfør en forbindelsestest på anlægget. Dette gøres via følgende menu gennemgang: Indstillinger -&gt; Generelt -&gt; Forbindelse -&gt; Test din forbindelse / Forbindelses test. Viser testen at forbindelsen er mislykket kan der være problemer med din router. Følgende porte skal være åbne i routeren: 443 og 80.</p><br />
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "kanikkeforbindeapp" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p>Der er 3 slags forbindelser der er tilgængelige:</p><br />
							<ol>
								<li>1: GSM. Mobilsignal der kan være lavt afhængig af installations forhold. Kommunikationen fra anlægget kan gå langsomt, med denne forbindelse.</li>
								<li>2: Wi-FI. Hurtig forbindelse uden ledning.</li>
								<li>3: LAN. Den mest stabile forbindelse, der trækkes med et kabel mellem anlæg og router.</li>
							</ol><br />
							<p>Anlægget er udstyret med en guide, så du nemt kan vælge og oprette forbindelse.</p><br />
							<p>Hvis din GSM forbindelse fejler, eller kun viser en signal streg, så brug en af de andre forbindelser.</p><br />
							<p>Hvis din Wi-Fi forbindelse lykkes men du stadig oplever problemer, så udfør en forbindelsestest på anlægget. Dette gøres via følgende menu gennemgang: Indstillinger -&gt; Generelt -&gt; Forbindelse -&gt; Test din forbindelse / Forbindelses test. Viser testen at forbindelsen er mislykket kan der være problemer med din router. Følgende porte skal være åbne i routeren: 443 og 80. Prøv at skifte til en LAN forbindelse og kontroller om det afhjælper problemet.</p><br />
							<p>Hvis din LAN forbindelse lykkes men du stadig oplever problemer, så udfør en forbindelsestest på anlægget. Dette gøres via følgende menu gennemgang: Indstillinger -&gt; Generelt -&gt; Forbindelse -&gt; Test din forbindelse / Forbindelses test. Viser testen at forbindelsen er mislykket kan der være problemer med din router. Følgende porte skal være åbne i routeren: 443 og 80.</p><br />
							<p>Når forbindelses testen lykkedes, er anlægget på nettet. Nu kan registreringen gennemføres. Registreringen findes under menupunktet: Indstillinger. Når registreringen er gennemført, skal du modtage en mail hvor du guides til aktivering af dit anlæg. Der kan godt gå en rum tid før mailen kommer. Efter aktiveringen burde anlægget automatisk dukke op i din app. Husk du skal være logget ind med samme mail som du brugte i registreringen. Disse kobles sammen i aktiveringen af produktet. Dukker dit anlæg ikke automatisk op i appen kan du trækker ned i forsiden og at opdaterer databasen i appen. OBS: Man kan ikke tilføje denne slags anlæg ved at trykke på: ”+ tilføj et bwt produkt” </p>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "ingenaktiveringsmail" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p>Udfør en forbindelsestest på anlægget. Dette gøres via følgende menu gennemgang: Indstillinger -&gt; Generelt -&gt; Forbindelse -&gt; Test din forbindelse / Forbindelses test. Viser testen at forbindelsen er mislykket kan der være problemer med din router. Følgende porte skal være åbne i routeren: 443 og 80.</p><br />
							<p>Der er 3 slags forbindelser der er tilgængelige:</p><br />
							<ol>
								<li>1: GSM. Mobilsignal der kan være lavt afhængig af installations forhold. Kommunikationen fra anlægget kan gå langsomt, med denne forbindelse.</li>
								<li>2: Wi-FI. Hurtig forbindelse uden ledning.</li>
								<li>3: LAN. Den mest stabile forbindelse, der trækkes med et kabel mellem anlæg og router.</li>
							</ol><br />
							<p>Anlægget er udstyret med en guide, så du nemt kan vælge og oprette forbindelse.</p><br />
							<p>Hvis din GSM forbindelse fejler, eller kun viser en signal streg, så brug en af de andre forbindelser.</p><br />
							<p>Hvis din Wi-Fi forbindelse lykkes men du stadig oplever problemer, så udfør en forbindelsestest på anlægget. Dette gøres via følgende menu gennemgang: Indstillinger -&gt; Generelt -&gt; Forbindelse -&gt; Test din forbindelse / Forbindelses test. Viser testen at forbindelsen er mislykket kan der være problemer med din router. Følgende porte skal være åbne i routeren: 443 og 80. Prøv at skifte til en LAN forbindelse og kontroller om det afhjælper problemet.</p><br />
							<p>Hvis din LAN forbindelse lykkes men du stadig oplever problemer, så udfør en forbindelsestest på anlægget. Dette gøres via følgende menu gennemgang: Indstillinger -&gt; Generelt -&gt; Forbindelse -&gt; Test din forbindelse / Forbindelses test. Viser testen at forbindelsen er mislykket kan der være problemer med din router. Følgende porte skal være åbne i routeren: 443 og 80.</p><br />
							<p>Når forbindelses testen lykkedes, er anlægget på nettet. Nu kan registreringen gennemføres. Registreringen findes under menupunktet: Indstillinger. Når registreringen er gennemført, skal du modtage en mail hvor du guides til aktivering af dit anlæg. Der kan godt gå en rum tid før mailen kommer. Efter aktiveringen burde anlægget automatisk dukke op i din app. Husk du skal være logget ind med samme mail som du brugte i registreringen. Disse kobles sammen i aktiveringen af produktet. Dukker dit anlæg ikke automatisk op i appen kan du trækker ned i forsiden og at opdaterer databasen i appen. OBS: Man kan ikke tilføje denne slags anlæg ved at trykke på: ”+ tilføj et bwt produkt” </p>
						</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "elektronikfejl" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p>Frakobl strømmen til anlægget og vent 2 minutter. Når anlægget igen kobles til strøm vil den forsøge at bringe sig selv tilbage i fejlfri tilstand.</p>
							</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "aqawatchudlost" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p>Forklaring: AQA Watch er et indbygget sikkerhedssystem der advarer ved meget små volumenstrømme (&lt; 60 l/h) over et længere tidsrum (&gt; 10 minutter).</p><br />
							<p>Hvis du ikke kan genkende det lille forbrug, kan det skyldes et problem i vandkredsen (f.eks. lækage, dryppende vandhave, toilet eller en utæthed).</p><br />
							<p>Hvis der rekvireres en tekniker hos BWT, og denne fejl ikke er forudsaget af defekt eller fejl på anlægget, men derimod er udløst grundet noget eksternt såsom, lækage, utætheder eller dryppende installationer i huset, vil besøget blive faktureret</p>
							</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome" || model === "bwtperlaone") && error === "aqastopliterudlost" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p>Forklaring: Aqa Stop Liter er en justerbar sikkerhedsløsning indbygget i anlægget. Den kan indstilles fra 1-9999 liter. Det der sker, er at anlægget tæller de antal liter der forbruges på anlæggets afgangsside. Hvis forbruget overstiger den indstillede værdi uden afbrydelse, vil anlægget lukke for vandtilførelsen, lyse rødt og alarmere dig via app og mail.</p><br />
							<p>Eksempel: Aqa Stop Liter indstillet til 600 liter. En slange hopper af vandhanen på badeværelset, og vandet strømmer ud og ledes hen til afløb. Efter 600 liter vil anlægget afbryde vandtilførelsen til husstanden og gå i alarm. Skadesforløbet bliver hermed afbrudt.</p><br />
							</div>
					) : null}

					{(model === "bwtperla" || model === "bwtperlahome") && error === "aqastopsensorudlost" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p>Forklaring: Aqa Stop Sensor er en lille gulvsensor der ligner to knapcellebatterier i en holder. Se evt. billede. Den sikrer de nærmeste omgivelser omkring anlægget. Den kan placeres i en radius omkring anlægget hvor man synes det giver mest mening. Hvis den bliver tilstrækkelig våd, vil anlægget lukke for vandet på afgangssiden, lyse rødt og alarmere brugeren  via app og mail.</p>
							<div className="mt-4">
								<img className="mb-2" src="aqastop.png" alt="" />
							</div>
						</div>
					) : null}

					{/* AQA Life og AQA basic */}

					{(model === "aqalife" || model === "aqabasic") && error === "almservice" ? (
						<div className="">
							<p className="text-gray-900 font-semibold">Et almindeligt serviceeftersyn omfatter:</p>
							<ul className="text-gray-600">
								<li>- Kontrol af installationen</li>
								<li>- Kontrol af regenerationsautomatik</li>
								<li>- Kontrol af brinemængde</li>
								<li>- Tjek af hydrauliske, elektriske og mekaniske dele</li>
								<li>- Udskift af slid- og reservedele</li>
								<li>- Udskiftning af klorcelle (sliddel) hver 4. år</li>
								<li>- Hårdhedstest og korrekte indstillinger af anlæg</li>
								<li>- Servicerapport med udført arbejde via e-mail</li>
							</ul>
						</div>
					) : null}

					{(model === "aqalife" || model === "aqabasic") && error === "kalderforsalt" ? (
						<div className="">
							{model === "aqalife" ? (
								<p>
									Hvis anlægget skriver ”Salt” {model == "aqalife" ? "eller ”lack of salt” ":""}i displayet har klorcellen ikke registreret salt i det vand den suger op i filteret,
									under regenerationen.
								</p>
							) : (
								<p>
									Hvis anlægger skriver 'Salt' eller 'lack of salt' i displayet har klorcellen ikke registreret salt i det vand den suger op i filteret
									under regenerationen. Eller også har anlægget ikke suget noget saltvand op.
								</p>
							)}
							<br />
							<ul className="text-gray-600">
								<li>- Tjek om der er salt og vand i anlægget.</li>
								<li>- Tjek om der er knæk på slangerne til og fra anlægget.</li>
								<li>- Tjek om haner på bypass til anlægget står åbent.</li>
								<li>- Tjek at samtlige stik er tilsluttet korrekt, inkl. klorcelle</li>
							</ul>
						</div>
					) : null}

					{(model === "aqalife") && error === "overcure" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">
								<p>
									Du kan forsøge at trykke ok (trykke, ikke holde) indtil alarmen forsvinder. Der kan godt skulle flere tryk til efter hinanden. Når anlægget er ude af alarmen, kan man frakoble strømmen og så vente 10 sekunder, og tilslutte igen. Dette kan måske få stempler tilbage i sin udgangsposition.
								</p><br />
								<p>
									Fejlen kommer typisk hvis der er sket en strømafbrydelse mens anlægget har regenereret eller hvis stempel og endeplade er slidt op. Det kan derfor være en midlertidig løsning at ”hjælpe” stemplet på plads med ovenstående teknik. 
								</p>
							</p>
						</div>
					) : null}

					{(model === "aqalife" || model === "aqabasic") && error === "service" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">
								Når dit BWT-anlæg viser {model == "aqalife" ? "'Wartung'" : "blinker i displayet"}, betyder det, at det anbefales at anlægget vedligeholdes eller serviceres. Det er en automatisk timer i
								anlægget der kommer med påmindelsen om service. Vi anbefaler altid et servicebesøg for at opretholde høj hygiejne og forlængelse af
								anlæggets levetid.
								<br />
								<br />
								Ønsker du ikke service kan du: <br />
								Nulstille ved at holde ok knappen inde i ca. 7 sekunder. Servicekaldet er tids- eller regenerations bestemt, så anlægget vil fortælle dig
								hvornår det er tid til service igen.
							</p>
						</div>
					) : null}

					{(model === "aqalife" || model === "aqabasic") && (error === "skylleriafloeb" || error === "taellerikkened") ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							{error === "skylleriafloeb" ? (
								<>	
									<p>I de fleste tilfælde vil der stå en fejl i displayet. Se om fejlen er beskrevet under de andre punkter. </p><br />
									<p>Kan du ikke finde fejlen eller skriver displayet ingen fejl, kan du prøve at frakoble strømmen fra anlægget, vente 2 minutter og køble strømmen til igen. </p><br />
									<p className="">
										Afhjælper dette ikke problemet skal du by-passe dit anlæg og booke et servicebesøg. Der er fejl på anlægget som skal udbedres af en BWT tekniker før anlægget virker
										korrekt igen.
									</p>
								</>
							) : (
								<>
									<p>Forklaring: Der sidder en måler på afgangensiden af anlægget der måler vander der kommer igennem. Derved tæller anlægget ned i kapacitet i form af liger der er tilbage.</p><br />
									<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p><br/>
									<ul>
										<li>- Tjek om haner på bypass til anlægget står åbent.</li>
									</ul><br />
									<p>Er bypasset åbnet vil vandet let kunne strømme uden om anlægget.</p>
									<p>Er bypasset ikke åbnet og vandet bliver ledt igennem anlægget uden at den tæller ned skal du by-passe dit anlæg og booke et servicebesøg. Der er fejlt på anlægget som skal udbedres af en BWT tekniker før anlægget virker korrekt igen. </p>
								</>
							)}

							<div className="relative flex flex-col items-center max-w-screen-xl mx-auto md:flex-row">
								<div className="flex items-center py-5 md:w-1/2 md:pb-20 md:pt-10 md:pr-10">
									<div className="text-left">
										<p className="text-gray-900 font-semibold">Sådan by-passer du dit anlæg</p>
										<p className="max-w-md mx-auto mt-3 text-base text-gray-500 md:mt-5 md:max-w-3xl">
											Der er typisk lukkehaner som på billedet:
											<br />
											Her ser du et bypass der betegnes som lukket. dvs. at de 2 haner der er tilsluttet anlægget, er åbne så vandet løber igennem
											og den sidste hane mellem de 2 andre er lukket. På denne måde ledes vandet gennem anlægget og der leveres blødt vand til
											husstanden.
											<br />
											Tommelfingerregel: Vandet løber samme vej som bladene på hanen vender. Altså, er hanen drejet så bladene går langs med røret
											er hanen åben og vandet kan løbe igennem. Er hanen på tværs af rørene kan vandet ikke løbe igennem og hanen er derfor lukket.
										</p>
									</div>
								</div>
								<div className="flex items-center md:w-1/2 ">
									<div className="relative w-full p-3 rounded  md:p-8">
										<div className="rounded-lg bg-white text-black w-full">
											<img src="image.png" />
										</div>
										<div className="rounded-lg bg-white text-black w-full">
											<img src="Picture1.png" />
										</div>
									</div>
								</div>
							</div>
						</div>
					) : null}

					{(model === "aqalife") && error === "saltbeholdertor" ? (
						<div>
							<p>Der skal altid være vand i saltbeholderen på dit anlæg. Vandstanden kan variere løbende. Men som tommelfinderregel skal det ca. være halvt fyldt. </p><br />
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p><br />
							<p>Der sidder en flyder inde i anlægget der sørger for at anlægget fylder vand i karret når der er behov. Denne kan sætte sig fast hvis salten i vandet opfugter omkring den og krystalliserer. For at få den løs igen kan man fylde karret til over halvdelen. Dette vil få salten på flyderen til at bliver opløst og så skulle den virke igen. </p><br />
							<p>Dette kan skyldes at anlægget står varmt, f.eks. op ad gulvvarmeanlæg, teknikskabe, eller direkte på gulvvarme</p>

							<p>Hvis dette ikke virker har du brug for et teknikerbesøg, da vi har behov for at komme ud og udbedre fejlen.</p><br />
							<p>Indtil vores tekniker kommer, vil vi bede dig om at hælde ca. 5 liter vand i saltbeholderen, og holde øje med at anlægget ikke løber tør.</p>
						</div>
					) : null}

					{(model === "aqabasic") && error === "saltbeholdertor" ? (
						<div>
							<p>Der skal altid være vand i saltbeholderen på dit anlæg. Vandstanden kan variere løbende. Men som tommelfinderregel skal det ca. være halvt fyldt. </p><br />
							<p>Bagpå anlægget sidder en sugelanse med en flyder, der sørger at anlægget fylder vand i karret når der er behov. Denne kan sætte sig fast hvis salten i vandet opfugter omkring den og krystalliserer.</p><br />
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p><br />
							<p>Sugelansen kan hives 4-5 cm op og ned. Først op, da den står på bunden. Man kan hive den op og ned i et tempo der ikke ødelægger den, men er hårdt nok til at ryste den fri for salt.</p><br />
							<p>Se disse billeder for for vejledning:</p>
							<div className="mt-4">
								<img className="mb-2" src="basicvandkarret.png" alt="" />
							</div>
							<p>Man kan også hælde lidt varm vand ned over flyderen gennem det lille runde hul. Så salten bliver opløst. </p>

							<p>Hvis disse metoder ikke virker har du brug for et teknikerbesøg, da vi har behov for at komme ud og udbedre fejlen.</p><br />
							<p>Indtil vores tekniker kommer, vil vi bede dig om at hælde ca. 5 liter vand i saltbeholderen, og holde øje med at anlægget ikke løber tør.</p>
						</div>
					) : null}

					{(model === "aqalife" || model === "aqabasic") && error === "saltskaeg" ? (
						<div>
							<p>Forklaring: salten der opløses i vandet, fordamper og sætter sig op ad indersiden på karret. Når dette har stået på i en længere periode, vil det nå helt op så det bliver synligt på ydersiden af anlægget og gennem alle sprækker og små huller der er. Billedet er et eksempel på hvordan det kan se ud: </p><br />
							<img src="saltskaeg.jpg" />
							<p>Dette skyldes at anlægget står et varmt og måske fugtigt sted. Dette kan være i et teknikskab, op ad gulvvarmeslanger, jordvarmeanlæg, eller direkte på gulvvarme.</p><br />
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p><br />
							<p>Salten kan opløses let med lunkent vand, brug evt. en klud. Brug ikke kemikalier. Sørg for at anlægget står et ventileret sted hvor der ikke bliver for varmt.</p>
						</div>
					) : null}

					{model === "aqalife" && error === "maalerhaardtvand" ? (
						<div>
							<p>Forklaring: Anlægget skal indstilles efter en indgangshårdhed (Råvand) og en udgangshårdhed (Behandlet vand). Disse måles i DH.</p><br />
							<p>Hvis du måler en højere hårdhed på vandet i din husstand, skal du være opmærksom på følgende:</p>
							<ul className="list-disc px-6">
								<li>Har hårdheden på dit råvand ændret sig?</li>
								Hårdheden på råvandet kan du måle ved at bypasse anlægget, og lade vandet løbe 1 min før du laver en hårdhedstest. Husk at tjekke udløbsdatoen på dit testsæt. Har hårdheden ændret sig skal du skrive de nye indstillinger ind i styringen. Se manualen.<br />
								<li>Har du fået lavet vvs arbejde, så dit bypass måske ikke længere står i den rigtige position?</li>
								Tjek om dit bypass står så alt vandet bliver ledt gennem anlægget.<br />
								<li>Har anlægget ændret forbrug af salt, mens dit vandforbrug har været uændret?</li>
								Hvis forbruget af salt har ændret, uden at vandforbruget har, kan det tyde på en fejl i anlægget og du bedes rekvirerer en tekniker .<br />
							</ul>
							<p>Der er også en god ide at tjekke hvornår du måler hårdt vand. Dette kan afklares med en kapacitetstest: </p>
						</div>
					) : null}

					{/* KUN AQA basic */}

					{model === "aqabasic" && error === "ikkevand" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">Har du tjekket at:</p>
							<ul className="text-gray-600">
								<li>- Der er vand i karet?</li>
								<li>- Der er salt i karet?</li>
								<li>- Klorstikket sidder i?</li>
							</ul>
						</div>
					) : null}

					{model === "aqabasic" && error === "maalerikkehaardheder" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">Det ser ud til at du skal have en servicetekniker på besøg.</p>
						</div>
					) : null}

					{model === "aqabasic" && error === "p05error" ? (
						<div>
							<p className="text-gray-900 font-semibold">Måske du selv nemt kan løse det, her er et forslag: </p>
							<p className="">
								Du kan forsøge at trykke ok (trykke, ikke holde) indtil alarmen forsvinder. Der kan godt skulle flere tryk til efter hinanden. Når anlægget er ude af alarmen, kan man frakoble strømmen og så vente 10 sekunder, og tilslutte igen. Dette kan måske få stempler tilbage i sin udgangsposition. 
							</p>
							<p>Fejlen kommer typisk hvis der er sket en strømafbrydelse mens anlægget har regenereret elelr hvis stempel og endeplade er slidt op. Der kan derfor være en midlertidig løsning at ”hjælpe” stemplet på plads med overstående teknik. </p>
						</div>
					) : null}

					<br />

					<div className="">
						<p className="text-gray-600">Fortsæt videre for at bestille en BWT servicetekniker</p>
					</div>
				</div>
			) : null}
		</>
	);
};

const CarouselItem: React.FC<CarouselItemProps> = ({ id, highligtedTitle, isEligibleForFreeServiceAggrement, aggType, price, li1, li2, li3, li4, li5,li6, onBtnClick, btnText, btnClass, type = "upg" }) => {
	return (
		<div key={id} className="border text-center mb-2 mr-1">
			<div className={`w-full ${aggType === "PREMIUM" ? "bg-[#FF88BD]" : aggType === "PLUS" ? "bg-[#00174B]" : "bg-[#666b6e]"} text-white py-2 mb-2`}>{highligtedTitle}</div>
			<div className="px-1">
				{aggType === "PREMIUM" ? (
					<img className="m-auto h-52" src="/premium.badge.jpg" alt="" />
				): null}
				{aggType === "PLUS" ? (
					<img className="m-auto h-52" src="/plus.badge.jpg" alt="" />
				): null}
				{aggType === "BASIC" ? (
					<img className="m-auto h-52" src="/basic.badge.jpg" alt="" />
				): null}
				<h3 className="text-md font-semibold my-2">TRYGHED {aggType.toUpperCase()}</h3>
				<ul className="text-sm text-left">
					<li>{li1 ? "✓" : (<span className='text-red-500'>✕</span>)} Arbejdstid og kørsel ved funktionsfejl</li>
					<li>{li2 ? "✓" : (<span className='text-red-500'>✕</span>)} Reservedele</li>
					<li>{li3 ? "✓" : (<span className='text-red-500'>✕</span>)} Gratis låneanlæg ved fejl på anlæg</li>
					<li>{li4 ? "✓" : (<span className='text-red-500'>✕</span>)} Fast servicebesøg hvert 2. år<sup>1</sup></li>
					<li>{li5 ? "✓" : (<span className='text-red-500'>✕</span>)} Nyt anlæg efter 10 år</li>
					{isEligibleForFreeServiceAggrement ? (
						<li>
							{li6 ? "✓" : "<span className='text-red-500'>✕</span>"} Første 2 år GRATIS<sup>1</sup>
						</li>
					) : null}
					{!isEligibleForFreeServiceAggrement ? <li>&nbsp;</li> : null}
				</ul>

				<p className="text-sm text-left font-bold mt-2">
					{isEligibleForFreeServiceAggrement ? (
						<span>Gratis ved valg af BWT Tryghed Premium aftale</span>
					) : (
						<span className={type === "upg" ? "text-[#FF88BD]" : ""}>
							{type === "upg" ? "Ny" : type ==="keep" ? "Nuværende" : ""} månedlig pris<sup>3</sup> {price} kr.{" "}
						</span>
					)}
				</p>
				<p className="text-xs text-left mt-2">
					{isEligibleForFreeServiceAggrement ? (
						<span>Herefter {price} kr. pr. måned</span>
					) : (
						<span>
							{type === "upg" ? "Ny" : type ==="keep" ? "Nuværende" : ""} mindste pris i 12 mdr.{" "}
							{((price * 12) / 1000).toFixed(3)} kr.{" "}
						</span>
					)}
				</p>
				<button
					className={`w-full py-2 my-2 hover:bg-white hover:text-black border border-[#00174B] text-sm text-center shadow-md border-1 rounded ${btnClass}`}
					onClick={onBtnClick}
				>
					{btnText}
				</button>
			</div>
		</div>
	);
}