import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./views/App";
import BookService from "./views/Book.service";
import RedigerService from "./views/rediger.booking";
import CalendarView from "./views/calendar.view";
import UserDates from "./views/user.dates";
import reportWebVitals from "./reportWebVitals";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import { createTheme, MantineProvider } from "@mantine/core";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const theme = createTheme({
	/** Put your mantine theme override here */
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<BrowserRouter basename={process.env.REACT_APP_BASENAME || ""}>
		<MantineProvider theme={theme}>
			<React.StrictMode>
				<Routes>
					<Route path="/" element={<App />} />
					<Route path="/bookservice" element={<BookService />} />
					<Route path="/redigerservice" element={<RedigerService />} />
					{/* <Route path="/calendar" element={<CalendarView />} /> */}
					<Route path="/user" element={<UserDates />} />
				</Routes>
				{/* <App /> */}
			</React.StrictMode>
		</MantineProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
